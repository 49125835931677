import { Select, Button, Card } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./empdataforrm.css";
import Main from "../Layouts/Main";

const EmpDataforrm = () => {
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	const navigate = useNavigate();
	const { Option } = Select;

	const currentYear = new Date().getFullYear();
	const years = Array.from({ length: 5 }, (_, index) => currentYear - index);

	
    const fetchData = async () => {
			navigate(`/viewemployees/${month}/${year}/`);
	};

	return (
		<Main>
			<div className="emp-data-container">
				<Card className="emp-data-card">
					<h1 className="emp-data-title">Employee Data</h1>

					<div className="emp-data-form-group">
						<label className="emp-data-label">Select Year</label>
						<Select
							className="emp-data-select"
							placeholder="Select Year"
							onChange={(value) => setYear(value)}
							value={year}>
							{years.map((yr) => (
								<Option key={yr} value={yr}>
									{yr}
								</Option>
							))}
						</Select>
					</div>

					<div className="emp-data-form-group">
						<label className="emp-data-label">Select Month</label>
						<Select
							className="emp-data-select"
							placeholder="Select Month"
							onChange={(value) => setMonth(value)}
							value={month}>
							<Option value="1">January</Option>
							<Option value="2">February</Option>
							<Option value="3">March</Option>
							<Option value="4">April</Option>
							<Option value="5">May</Option>
							<Option value="6">June</Option>
							<Option value="7">July</Option>
							<Option value="8">August</Option>
							<Option value="9">September</Option>
							<Option value="10">October</Option>
							<Option value="11">November</Option>
							<Option value="12">December</Option>
						</Select>
					</div>

					<Button
						className="emp-data-button"
						type="primary"
						onClick={fetchData}>
						View
					</Button>
				</Card>
			</div>
		</Main>
	);
};

export default EmpDataforrm;
