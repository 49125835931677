import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, message, Spin } from "antd";
import { useAuth } from "../utils/useAuth";

const ResignationManagerView = () => {
	const { apiurl, token } = useAuth();
	const [resignations, setResignations] = useState([]);
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [selectedResignation, setSelectedResignation] = useState(null);

	const fetchResignations = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/resignations/manager/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch resignations");
			}
			const data = await response.json();
			setResignations(data);
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleCommentSubmit = async (values) => {
		try {
			const response = await fetch(`${apiurl}/resignations/manager/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: selectedResignation.id,
					comments: values.comments,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to submit comments");
			}
			message.success("Comments submitted successfully");
			form.resetFields();
			setSelectedResignation(null);
			fetchResignations();
		} catch (error) {
			message.error(error.message);
		}
	};

	useEffect(() => {
		fetchResignations();
	}, [apiurl, token]);

	const columns = [
		{
			title: "Timestamp",
			dataIndex: "timestamp",
			key: "timestamp",
			render: (text) => new Date(text).toLocaleString(),
		},
		{
			title: "Reason",
			dataIndex: "reason",
			key: "reason",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => text.charAt(0).toUpperCase() + text.slice(1),
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<>
					{!record.bo_comments && !record.hr_comments && !record.rm_comments ? (
						<Button
							type="primary"
							onClick={() => {
								setSelectedResignation(record);
								form.setFieldsValue({ comments: "" });
							}}>
							Add Comment
						</Button>
					) : (
						<span>Comments Already Provided</span>
					)}
				</>
			),
		},
	];

	const expandedRowRender = (record) => {
		return (
			<div className="resignation-details">
				<p>
					<strong>Handoverings:</strong>
				</p>
				<pre>{record.handoverings}</pre>
				<p>
					<strong>Notice Period Till:</strong>{" "}
					{new Date(record.noticeperiodtill).toLocaleDateString()}
				</p>
				<p>
					<strong>Bussiness Unit Head Comments:</strong>
				</p>
				<pre>{record.bo_comments || "No comments"}</pre>
				<p>
					<strong>HR Comments:</strong>
				</p>
				<pre>{record.hr_comments || "No comments"}</pre>
				<p>
					<strong>Reporting Manager Comments:</strong>
				</p>
				<pre>{record.rm_comments || "No comments"}</pre>
			</div>
		);
	};

	return (
		<div className="resignation-manager-view">
			<h2>Resignation Manager View</h2>
			{loading ? (
				<Spin />
			) : (
				<Table
					columns={columns}
					dataSource={resignations}
					rowKey={(record) => record.id}
					expandable={{
						expandedRowRender,
						rowExpandable: (record) => true, // Allow all rows to be expandable
					}}
					pagination={false}
				/>
			)}

			<Modal
				title="Add Comment"
				visible={selectedResignation !== null}
				onCancel={() => setSelectedResignation(null)}
				footer={null}>
				<Form form={form} layout="vertical" onFinish={handleCommentSubmit}>
					<Form.Item
						label="Comment"
						name="comments"
						rules={[{ required: true, message: "Please add a comment!" }]}>
						<Input.TextArea rows={4} placeholder="Enter your comment" />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Submit Comment
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default ResignationManagerView;
