import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	Form,
	Input,
	Modal,
	message,
	Select,
	Switch,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Policy.css";
import LeaveApprovalFlow from "./LeaveApprovalFlow";

const LeavePolicy = () => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [isPolicy, setIsPolicy] = useState(false);
	const [isLeaveForwardingEnabled, setIsLeaveForwardingEnabled] =
		useState(false);
	const [isAutoApproved, setIsAutoApproved] = useState(false);

	useEffect(() => {
		fetchPolicy();
	}, []);

	const fetchPolicy = async () => {
		setLoading(true); 
		try {
			const response = await fetch(`${apiurl}/leavepolicy1/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setInitialValues(data.data);
				setIsAutoApproved(data.data.autoApproval);
				setIsLeaveForwardingEnabled(data.data.leaveForwarding);
				setIsPolicy(true);
			} else {
				setIsPolicy(false);
			}
		} catch (error) {
			message.error(error.message);
			setIsPolicy(false);
		} finally {
			setLoading(false);
		}
	};

	const handleCreate = async (values) => {
		setLoading(true);
		try {
			values = {
				...values,
				childid: sessionStorage.getItem("selectedChildId"),
			};
			const response = await fetch(`${apiurl}/leavepolicy/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Policy framed successfully");
				setCreateMode(false);
				fetchPolicy();
			} else {
				message.error("Failed to frame policy");
			}
		} catch (error) {
			message.error(error.message || "Error framing policy");
		} finally {
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
		setLoading(true);
		try {
			values = {
				...values,
				lp_id: initialValues.id,
				childid: sessionStorage.getItem("selectedChildId"),
			};
			const response = await fetch(`${apiurl}/leavepolicy/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details updated successfully");
				fetchPolicy();
				setEditMode(false);
			} else {
				message.error("Failed to update details");
			}
		} catch (error) {
			message.error(error.message || "Error updating details");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="leave-policy-main">
			{loading ? (
				<div>Loading...</div>
			) : (
				<>
					{isPolicy ? (
						<Card
							className="leave-policy-card"
							bordered={false}
							title="Leave Policy"
							extra={
								<Button
									icon={<EditOutlined />}
									onClick={() => setEditMode(true)}
								/>
							}>
							<div className="leave-policy-items">
								<div className="leave-policy-item">
									<span className="leave-policy-label">Sick Leaves:</span>
									<span className="leave-policy-value">
										{initialValues.sickLeaves}
									</span>
								</div>
								<div className="leave-policy-item">
									<span className="leave-policy-label">Casual Leaves:</span>
									<span className="leave-policy-value">
										{initialValues.casualLeaves}
									</span>
								</div>
							</div>
							<div className="leave-policy-items">
								<div className="leave-policy-item">
									<span className="leave-policy-label">Maternity Leaves:</span>
									<span className="leave-policy-value">
										{initialValues.maternityLeaves}
									</span>
								</div>
								<div className="leave-policy-item">
									<span className="leave-policy-label">Paternity Leaves:</span>
									<span className="leave-policy-value">
										{initialValues.paternity_leaves}
									</span>
								</div>
							</div>
							<div className="leave-policy-items">
								<div className="leave-policy-item">
									<span className="leave-policy-label">Privilege Leaves:</span>
									<span className="leave-policy-value">
										{initialValues.privilege_leaves}
									</span>
								</div>
								<div className="leave-policy-item">
									<span className="leave-policy-label">
										Bereavement Leaves:
									</span>
									<span className="leave-policy-value">
										{initialValues.bereavement_leaves}
									</span>
								</div>
							</div>
							<div className="leave-policy-item">
								<span className="leave-policy-label">Total Leaves:</span>
								<span className="leave-policy-value">
									{initialValues.leaves_per_year}
								</span>
							</div>

							<div className="leave-policy-item">
								<span className="leave-policy-label">Leave Forwading:</span>
								<span className="leave-policy-value">
									<Switch disabled defaultChecked={isLeaveForwardingEnabled} />
								</span>
							</div>

							{isLeaveForwardingEnabled && (
								<>
									<div className="leave-policy-item">
										<span className="leave-policy-label">
											Forward Leave After (hours):
										</span>
										<span className="leave-policy-value">
											{initialValues.leaveForwardAfter || "N/A"}
										</span>
									</div>
									<div className="leave-policy-item">
										<span className="leave-policy-label">
											Leave Forwarding Upto:
										</span>
										<span className="leave-policy-value">
											{initialValues.leaveForwardingUpto || "N/A"}
										</span>
									</div>
								</>
							)}

							<div className="leave-policy-item">
								<span className="leave-policy-label">Auto Approval:</span>
								<span className="leave-policy-value">
									<Switch disabled defaultChecked={isAutoApproved} />
								</span>
							</div>

							{isAutoApproved && (
								<>
									<div className="leave-policy-item">
										<span className="leave-policy-label">
											Leave Auto Approved Before (hours):
										</span>
										<span className="leave-policy-value">
											{initialValues.autoApprovalBefore || "N/A"}
										</span>
									</div>
								</>
							)}
						</Card>
					) : (
						<>
							<h3>Policy Not Yet Created.</h3>
							<Button
								className="leave-policy-button"
								onClick={() => setCreateMode(true)}>
								Create Policy
							</Button>
						</>
					)}
					<Modal
						open={createMode}
						title="Create Leave Policy Annually"
						destroyOnClose
						onCancel={() => setCreateMode(false)}
						footer={[
							<Button key="cancel" onClick={() => setCreateMode(false)}>
								Cancel
							</Button>,
							<Button
								key="submit"
								type="primary"
								loading={loading}
								onClick={() => form.submit()}>
								Submit
							</Button>,
						]}>
						<Form
							form={form}
							layout="vertical"
							onFinish={handleCreate}
							className="leave-policy-form">
							<Form.Item required name="sickLeaves" label="Sick Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item required name="casualLeaves" label="Casual Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item
								required
								name="maternityLeaves"
								label="Maternity Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item
								required
								name="paternity_leaves"
								label="Paternity Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item
								required
								name="bereavement_leaves"
								label="Bereavement Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item
								required
								name="privilege_leaves"
								label="Privilege Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item required name="leaves_per_year" label="Total Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>

							<Form.Item label="Leave Forwarding" valuePropName="checked">
								<Switch
									checked={isLeaveForwardingEnabled}
									onChange={setIsLeaveForwardingEnabled}
								/>
							</Form.Item>
							<Form.Item
								required
								name="leaveForwardAfter"
								label="Forward Leave After (hours)"
								style={{
									display: isLeaveForwardingEnabled ? "block" : "none",
								}}>
								<Input type="number" className="leave-policy-input" />
							</Form.Item>

							<Form.Item
								required
								name="leaveForwardingUpto"
								label="Leave Forwarding Upto"
								style={{
									display: isLeaveForwardingEnabled ? "block" : "none",
								}}>
								<Select>
									<Select.Option value="HR">HR</Select.Option>
									<Select.Option value="Business Owner">
										Business Owner
									</Select.Option>
									<Select.Option value="Reporting Manager">
										Reporting Manager
									</Select.Option>
								</Select>
							</Form.Item>

							<Form.Item label="Auto Approval" valuePropName="checked">
								<Switch checked={isAutoApproved} onChange={setIsAutoApproved} />
							</Form.Item>
							<Form.Item
								required
								name="autoApprovalBefore"
								label="Auto Approval Before (hours)"
								style={{
									display: isAutoApproved ? "block" : "none",
								}}>
								<Input type="number" className="leave-policy-input" />
							</Form.Item>

							<Form.Item>
								<span style={{ fontSize: "12px", color: "#888" }}>
									*All details are entered per year.
								</span>
							</Form.Item>
						</Form>
					</Modal>

					<Modal
						open={editMode}
						destroyOnClose
						title="Edit Details"
						onCancel={() => setEditMode(false)}
						footer={[
							<Button key="cancel" onClick={() => setEditMode(false)}>
								Cancel
							</Button>,
							<Button
								key="submit"
								type="primary"
								loading={loading}
								onClick={() => form.submit()}>
								Submit
							</Button>,
						]}>
						<Form
							form={form}
							layout="vertical"
							onFinish={handleUpdate}
							initialValues={initialValues}
							className="leave-policy-form">
							<Form.Item required name="sickLeaves" label="Sick Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item required name="casualLeaves" label="Casual Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item
								required
								name="maternityLeaves"
								label="Maternity Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item
								required
								name="paternity_leaves"
								label="Paternity Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item
								required
								name="bereavement_leaves"
								label="Bereavement Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item
								required
								name="privilege_leaves"
								label="Privilege Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>
							<Form.Item required name="leaves_per_year" label="Total Leaves">
								<Input className="leave-policy-input" />
							</Form.Item>

							<Form.Item label="Auto Approval" valuePropName="checked">
								<Switch checked={isAutoApproved} onChange={setIsAutoApproved} />
							</Form.Item>
							<Form.Item
								required
								name="autoApprovalBefore"
								label="Auto Approval Before (hours)"
								style={{
									display: isAutoApproved ? "block" : "none",
								}}>
								<Input type="number" className="leave-policy-input" />
							</Form.Item>

							<Form.Item label="Leave Forwarding" valuePropName="checked">
								<Switch
									checked={isLeaveForwardingEnabled}
									onChange={setIsLeaveForwardingEnabled}
								/>
							</Form.Item>
							<Form.Item
								required
								name="leaveForwardAfter"
								label="Forward Leave After (hours)"
								style={{
									display: isLeaveForwardingEnabled ? "block" : "none",
								}}>
								<Input type="number" className="leave-policy-input" />
							</Form.Item>
							<Form.Item
								required
								name="leaveForwardingUpto"
								label="Leave Forwarding Upto"
								style={{
									display: isLeaveForwardingEnabled ? "block" : "none",
								}}>
								<Select>
									<Select.Option value="HR">HR</Select.Option>
									<Select.Option value="Business Owner">
										Business Owner
									</Select.Option>
									<Select.Option value="Reporting Manager">
										Reporting Manager
									</Select.Option>
								</Select>
							</Form.Item>

							<Form.Item>
								<span style={{ fontSize: "12px", color: "#888" }}>
									*All details are entered per year.
								</span>
							</Form.Item>
						</Form>
					</Modal>

					<LeaveApprovalFlow />
				</>
			)}
		</div>
	);
};

export default LeavePolicy;
