import React, { useEffect, useState } from "react";
import { useAuth } from "../../utils/useAuth";
import { useNavigate } from "react-router-dom";
import "./Style.css";
import HeaderComponent from "../../Header/Header";

const ChildDetails = () => {
    const navigate = useNavigate();
    const { apiurl, token } = useAuth();
    const [childdata, setChildData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchChilds();
    }, []);

    const handleChildClick = (id, type) => {
        sessionStorage.setItem("selectedChildId", id);
        sessionStorage.setItem("selectedAttType", type);
        navigate(`/home`);
    };

    const fetchChilds = async () => {
        try {
            const response = await fetch(`${apiurl}/fetchchildaccounts/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch child data');
            }

            const info = await response.json();
            if (info.data === "SUPER_USER") {
                navigate("/home");
            } else {
                setChildData(info.data);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
			<HeaderComponent>
				<div className="child-details-container">
					<h1 className="section-head">Select Business Unit</h1>
					<p>
						Please choose one of the associated business units for your company.
					</p>
					{loading && <p className="loading-message">Loading...</p>}
					{error && <p className="error-message">{error}</p>}
					{!loading && !error && childdata && (
						<div className="child-list">
							{childdata.map((child, index) => (
								<div
									className="child-item"
									key={index}
									onClick={() =>
										handleChildClick(child.id, child.attendance_type)
									}>
									<center>{child.child}</center>
								</div>
							))}
						</div>
					)}
				</div>
			</HeaderComponent>
		);
};

export default ChildDetails;
