import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table, Button, Modal, Form, Input, message, Space } from "antd";
import { useAuth } from "../utils/useAuth";
import { ArrowLeftOutlined } from "@ant-design/icons"; 
import HeaderComponent from "../Header/Header";

const AssetDetailsView = () => {
	const { apiurl, token } = useAuth();
	const { asset_id } = useParams();
	const [assetDetails, setAssetDetails] = useState([]);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm();
	const [editingDetail, setEditingDetail] = useState(null);

	useEffect(() => {
		fetchAssetDetails();
	}, [asset_id]);

	const fetchAssetDetails = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${apiurl}/asset-details?asset_id=${asset_id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			const data = await response.json();
			setAssetDetails(data);
		} catch (error) {
			message.error("Failed to fetch asset details.");
		}
		setLoading(false);
	};

	const handleSubmit = async (values) => {
		const url = editingDetail
			? `${apiurl}/asset-details`
			: `${apiurl}/asset-details`;
		const method = editingDetail ? "PUT" : "POST";
		const assetDetailData = {
			...values,
			asset_id: asset_id,
			id: editingDetail?.id,
		};

		try {
			const response = await fetch(url, {
				method,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(assetDetailData),
			});

			const result = await response.json();

			if (response.ok) {
				message.success(
					editingDetail
						? "Asset detail updated successfully"
						: "Asset detail added successfully"
				);
				fetchAssetDetails();
				setVisible(false);
				form.resetFields();
			} else {
				message.error(result.error || "An error occurred");
			}
		} catch (error) {
			message.error("Failed to submit asset detail.");
		}
	};

	const handleDelete = async (id) => {
		try {
			const response = await fetch(`${apiurl}/asset-details`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ id }),
			});

			if (response.ok) {
				message.success("Asset detail deleted successfully");
				fetchAssetDetails();
			} else {
				const result = await response.json();
				message.error(result.error || "Failed to delete asset detail");
			}
		} catch (error) {
			message.error("Failed to delete asset detail.");
		}
	};

	const openModal = (detail = null) => {
		setEditingDetail(detail);
		setVisible(true);
		if (detail) {
			form.setFieldsValue({
				serial_number: detail.serial_number,
				configuration: detail.configuration,
			});
		} else {
			form.resetFields();
		}
	};

	const columns = [
		{
			title: "Serial Number",
			dataIndex: "serial_number",
			key: "serial_number",
		},
		{
			title: "Configuration",
			dataIndex: "configuration",
			key: "configuration",
			render: (text) => (
				<div style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
					{text.length > 100 ? `${text.slice(0, 100)}...` : text}
				</div>
			),
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<Space>
					<Button primary onClick={() => openModal(record)}>
						Edit
					</Button>
					<Button danger onClick={() => handleDelete(record.id)}>
						Delete
					</Button>
				</Space>
			),
		},
	];

	const handleBack = () => {
		window.history.back();
	};

	return (
		<HeaderComponent>
			<div className="assetdetails__container">
				<div className="assetdetails__header">
					<Button
						className="assets_main__back-button"
						onClick={handleBack}
						icon={<ArrowLeftOutlined />}>
						Back
					</Button>
					<h1 className="assetdetails__title">Asset Details</h1>
				</div>
				<Button
					primary
					className="assetdetails__add-button"
					onClick={() => openModal()}
					style={{ marginBottom: "20px" }}>
					Add Asset Detail
				</Button>
				{loading ? (
					<p>Loading...</p>
				) : (
					<div className="assetdetails__table-wrapper">
						<Table
							dataSource={assetDetails}
							columns={columns}
							rowKey="id"
							pagination={false}
							className="assetdetails__table"
						/>
					</div>
				)}

				<Modal
					title={editingDetail ? "Edit Asset Detail" : "Add Asset Detail"}
					open={visible}
					onCancel={() => setVisible(false)}
					onOk={() => form.submit()}
					className="assetdetails__modal">
					<Form form={form} layout="vertical" onFinish={handleSubmit}>
						<Form.Item
							label="Serial Number"
							name="serial_number"
							className="assetdetails__form-item"
							rules={[
								{ required: true, message: "Please enter the serial number" },
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label="Configuration"
							name="configuration"
							className="assetdetails__form-item"
							rules={[
								{ required: true, message: "Please enter the configuration" },
							]}>
							<Input.TextArea />
						</Form.Item>
					</Form>
				</Modal>
			</div>
		</HeaderComponent>
	);
};

export default AssetDetailsView;
