import React, { useState, useEffect } from 'react';
import { useAuth } from "../utils/useAuth";
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Button, Modal, Select, message, Form, Input } from 'antd';
import Main from "../Homepages/Layouts/Main";

const EditEmpAtt = () => {
    const { apiurl, token } = useAuth();
    const navigate=useNavigate()
    const { month, year, id } = useParams();
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { Option } = Select;
    const [currentEdit, setCurrentEdit] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [timeIn, setTimeIn] = useState(null);
    const [timeOut, setTimeOut] = useState(null);
    const [empAttData, setEmpAttData] = useState(null);

    useEffect(() => {
        fetchAttendance();
    }, []);

    const formatTime = (timeStr) => {
        if (!timeStr) return "";
        const [hours, minutes] = timeStr.split(":").map(Number);
        return hours === 0 ? `${minutes} mins` : `${hours} hr ${minutes} mins`;
    };

    const showModal = (record) => {
        setCurrentEdit(record);
        setTimeIn(record.time_in);
        setTimeOut(record.time_out);
        setSelectedStatus(record.status);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentEdit(null);
        setSelectedStatus(null);
        setTimeIn(null);
        setTimeOut(null);
    };

    const handleStatusChange = (value) => {
        setSelectedStatus(value);
    };

    const handleTimeInChange = (e) => {
        setTimeIn(e.target.value);
    };

    const handleTimeOutChange = (e) => {
        setTimeOut(e.target.value);
    };

    const handleSave = async () => {
        if (currentEdit && selectedStatus ) {
            try {
                const response = await fetch(`${apiurl}/updateattrecord/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ 
                        childid:sessionStorage.getItem('selectedChildId'),
                        id: currentEdit.id, 
                        status: selectedStatus,
                        time_in: timeIn,
                        time_out: timeOut
                    }),
                });

                if (response.ok) {
                    fetchAttendance();
                    setIsModalVisible(false);
                    setCurrentEdit(null);
                    setSelectedStatus(null);
                    setTimeIn(null);
                    setTimeOut(null);
                    const data = await response.json();
                    message.success(data.success);
                } else {
                    console.error('Failed to update the attendance record');
                }
            } catch (error) {
                console.error('Error updating the attendance record', error);
            }
        }
    };

    const fetchAttendance = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiurl}/fetchattrecords/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ empid: id, month: month, year: year ,childid:sessionStorage.getItem('selectedChildId'),}),
            });
            

            if (response.ok) {
                const data = await response.json();
                setEmpAttData(data.data);
                setLoading(false);
            }
            else{
                const data = await response.json();
                message.error(data.error);
                setLoading(false)
            }
        } catch (error) {
            console.error('Error fetching attendance data', error);
        }
    };

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Time In",
            dataIndex: "time_in",
            key: "time_in",
            render: (text) => (text ? <p>{text}</p> : <p>-</p>),
        },
        {
            title: "Time Out",
            dataIndex: "time_out",
            key: "time_out",
            render: (text) => (text ? <p>{text}</p> : <p>-</p>),
        },
        {
            title: "Net Break Time",
            dataIndex: "net_break",
            key: "net_break",
            render: (text) => (text ? <p>{formatTime(text)}</p> : <p>-</p>),
        },
        {
            title: "Net Reported Work Time",
            dataIndex: "net_time_in",
            key: "net_time_in",
            render: (text) => (text ? <p>{formatTime(text)}</p> : <p>-</p>),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Update Status",
            dataIndex: "update",
            key: "update",
            render: (_, record) => (
                <Button onClick={() => showModal(record)}>Edit</Button>
            ),
        },
    ];

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Main>
            EditEmpAtt
            <div>
                <Button onClick={()=>navigate(-1)}>Go Back</Button>
            </div>
            {empAttData && <Table dataSource={empAttData} columns={columns}></Table>}
            <Modal
                title="Edit Attendance"
                visible={isModalVisible}
                onOk={handleSave}
                onCancel={handleCancel}
                okText="Save"
                cancelText="Cancel"
            >
                <Form>
                    <Form.Item label="Time In">
                        <Input type="time" value={timeIn} onChange={handleTimeInChange} />
                    </Form.Item>
                    <Form.Item label="Time Out">
                        <Input type="time" value={timeOut} onChange={handleTimeOutChange} />
                    </Form.Item>
                    <Form.Item label="Update Status">
                        <Select onChange={handleStatusChange} value={selectedStatus}>
                            <Option value="present">Present</Option>
                            <Option value="absent">Absent</Option>
                            <Option value="leave">Leave</Option>
                            <Option value="latelogin">Late Login</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </Main>
    );
};

export default EditEmpAtt;
