import React, { useEffect, useState } from "react";
import Main from "../Layouts/Main";
import { useAuth } from "../../utils/useAuth";
import { message, Tabs } from "antd";
import BasicDetailsModal from "../../EmployeeInfo/BasicDetails";
import "./Profile.css";
import Documents from "../../EmployeeInfo/Documents";
import EducationDetails from "../../EmployeeInfo/EducationDetails";
import Relationships from "../../EmployeeInfo/Relationships";
import ExperienceDetails from "../../EmployeeInfo/WorkExperience";
import Occasions from "../../EmployeeInfo/Occasions";
import { useParams } from "react-router-dom";
import BankDetails from "../../EmployeeInfo/BankDetails";

const EmployeeInfo = () => {
	const [empData, setEmpData] = useState(null);
	const { apiurl, token } = useAuth();
	const [profileImg, setProfileImg] = useState("");
	const id = useParams();

	useEffect(() => {
		fetchEmployeeDetails();
		fetchImages();
	}, []);

	const fetchImages = async () => {
		try {
			const response = await fetch(`${apiurl}/gethandleimages/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(id),
			});
			if (response.ok) {
				const data = await response.json();
				setProfileImg(data.data.profile_image);
			} else {
				const data = await response.json();
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error fetching images");
		}
	};

	const fetchEmployeeDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/getcreateemployee/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(id),
			});

			const data = await response.json();

			if (response.ok) {
				setEmpData(data.data);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("An error occurred while processing your request.");
		}
	};

	return (
		<Main>
			<div className="profile-container">
				<div className="head-sec">
					<div className="cover-photo"></div>

					<div className="profile-header">
						<div className="profile-avatar">
							<img src={`${apiurl}${profileImg}`} alt="profile" />
						</div>
						<div className="profile-info">
							<h1>{empData?.userName}</h1>
							<div>
								<p className="profile-role">
									📍{empData?.type?.replace(/_/g, " ")}
								</p>

								<p className="profile-location">🎯{empData?.designation}</p>
								<p className="profile-join-date">📅{empData?.dateOfBirth}</p>
							</div>
						</div>
					</div>
				</div>
				<Tabs type="card" defaultActiveKey="1" className="profile-tabs">
					<Tabs.TabPane tab="Basic Details" key="1">
						<BasicDetailsModal />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Bank Details" key="2">
						<BankDetails />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Documents" key="3">
						<Documents />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Education" key="4">
						<EducationDetails />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Relationships" key="5">
						<Relationships />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Occasions" key="6">
						<Occasions />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Experience" key="7">
						<ExperienceDetails />
					</Tabs.TabPane>
				</Tabs>
			</div>
		</Main>
	);
};

export default EmployeeInfo;
