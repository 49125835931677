import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Select } from "antd";
import "./Home.css";
import { useAuth } from "../../utils/useAuth";

const { Option } = Select;

const AccountsManagerHome = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [yearMonthData, setYearMonthData] = useState({});
	const navigate = useNavigate();
	const { apiurl, token } = useAuth();

	useEffect(() => {
		const fetchYearMonthData = async () => {
			try {
				const childId = sessionStorage.getItem("selectedChildId");
				const response = await fetch(`${apiurl}/get-years-months/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ id: childId }),
				});
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				const data = await response.json();
				setYearMonthData(data.data);
			} catch (error) {
				console.error("Error fetching year and month data:", error);
			}
		};

		fetchYearMonthData();
	}, [apiurl, token]);

	const handleYearChange = (value) => {
		setYear(value);
	};

	const handleMonthChange = (value) => {
		setMonth(value);
	};

	const handleRunPayroll = () => {
		navigate(`/accounts/payroll/${month}/${year}`);
	};

	const yearOptions = Object.keys(yearMonthData);

	let monthOptions = [];
	if (yearMonthData[year]) {
		monthOptions = yearMonthData[year];
	}

	return (
		<>
			<br></br>
			<div className="business-owner-home">
				<h2>ACCOUNTS MANAGER FUNCTIONALITIES</h2>
				<div className="action-item" onClick={() => setIsModalVisible(true)}>
					<div className="action-item-header">Approve Payroll</div>
					<div className="action-item-body">
						Click here to view payroll of all the employees in your organization for the selected year and month.
					</div>
				</div>
			</div>
			<Modal
				title="View Payroll"
				className="payroll-run-modal"
				open={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				footer={null}>
				<div>
					<div className="choice-selection">
						<Select
							style={{ width: 120 }}
							value={year}
							placeholder="Select Year"
							onChange={handleYearChange}>
							{yearOptions.map((y) => (
								<Option key={y} value={y}>
									{y}
								</Option>
							))}
						</Select>
						<Select
							style={{ width: 120 }}
							value={month}
							placeholder="Select Month"
							onChange={handleMonthChange}>
							{monthOptions.map((m) => (
								<Option key={m} value={m}>
									{new Date(0, m - 1).toLocaleString("default", {
										month: "long",
									})}
								</Option>
							))}
						</Select>
					</div>
					<div className="run-btn">
						<Button onClick={handleRunPayroll}>View Payroll</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default AccountsManagerHome;
