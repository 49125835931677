import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Table, message, Modal, Input } from "antd";
import { useAuth } from "../utils/useAuth";
import HeaderComponent from "../Header/Header";
import { LeftCircleOutlined } from "@ant-design/icons";
import "./Payroll.css";
import Loader from "../Loader/Loader";
import Success from "../utils/Success";

const AccountsPayroll = () => {
	const [payrollData, setPayrollData] = useState([]);
	const navigate = useNavigate();
	const [isApprovalModalVisible, setIsApprovalModalVisible] = useState(false);
	const [isRejectionModalVisible, setIsRejectionModalVisible] = useState(false);
	const [rejectionReason, setRejectionReason] = useState("");
	const [loading, setLoading] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const { apiurl, token } = useAuth();
	const { month, year } = useParams();

	const fetchPayrollData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/accounts/payroll/preview/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					month: month,
					year: year,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch payroll data");
			}
			const data = await response.json();
			setPayrollData(data);
		} catch (error) {
			message.error("Error fetching payroll data");
		} finally {
			setLoading(false);
		}
	};

	const handleApproval = async () => {
		setButtonLoading(true);
		try {
			const response = await fetch(`${apiurl}/accounts/payroll/preview/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					month: month,
					year: year,
					childid: sessionStorage.getItem("selectedChildId"),
					status: "Approved",
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to approve payroll");
			}
			message.success("Payroll Approved Successfully");
			setSuccess(true);
		} catch (error) {
			message.error("Error approving payroll");
		} finally {
			setButtonLoading(false);
			setIsApprovalModalVisible(false);
		}
	};

	const handleRejection = async () => {
		if (!rejectionReason.trim()) {
			message.error("Please provide a reason for rejection");
			return;
		}
		setButtonLoading(true);
		try {
			const response = await fetch(`${apiurl}/accounts/payroll/preview/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					month: month,
					year: year,
					childid: sessionStorage.getItem("selectedChildId"),
					status: "Rejected",
					reason: rejectionReason,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to reject payroll");
			}
			message.success("Payroll Rejected Successfully");
		} catch (error) {
			message.error("Error rejecting payroll");
		} finally {
			setButtonLoading(false);
			setIsRejectionModalVisible(false);
			setRejectionReason("");
		}
	};

	useEffect(() => {
		fetchPayrollData();
	}, []);

	const payrollcolumns = [
		{ title: "Parent", dataIndex: "parent", key: "parent" },
		{ title: "Child", dataIndex: "child", key: "child" },
		{
			title: "Employee Name",
			dataIndex: ["employee", "userName"],
			key: "userName",
		},
		{
			title: "Employee ID",
			dataIndex: ["employee", "employeeid"],
			key: "employeeid",
		},
		{
			title: "Designation",
			dataIndex: ["employee", "designation"],
			key: "designation",
		},
		{
			title: "Department",
			dataIndex: ["employee", "department"],
			key: "department",
		},
		{ title: "Month", dataIndex: "month", key: "month" },
		{ title: "Year", dataIndex: "year", key: "year" },
		{ title: "Gross Salary", dataIndex: "gross", key: "gross" },
		{ title: "LOP", dataIndex: "lop", key: "lop" },
		{ title: "Number of Leaves", dataIndex: "no_leaves", key: "no_leaves" },
		{ title: "Basic Salary", dataIndex: "basic_salary", key: "basic_salary" },
		{
			title: "Allowances",
			dataIndex: "allowances",
			key: "allowances",
			render: (allowances) => {
				const parsedAllowances = JSON.parse(allowances);
				return (
					<div>
						<p>HRA: {parsedAllowances.HRA}</p>
						<p>Travel: {parsedAllowances.Travel}</p>
						<p>Special: {parsedAllowances.Special}</p>
					</div>
				);
			},
		},
		{ title: "Net Salary", dataIndex: "net_salary", key: "net_salary" },
		{ title: "PF Deduction", dataIndex: "pf_deduction", key: "pf_deduction" },
		{
			title: "Tax Deduction",
			dataIndex: "tax_deduction",
			key: "tax_deduction",
		},
		{ title: "Employer PF", dataIndex: "employer_pf", key: "employer_pf" },
		{ title: "Employer ESI", dataIndex: "employer_esi", key: "employer_esi" },
		{
			title: "ESI Deduction",
			dataIndex: "esi_deduction",
			key: "esi_deduction",
		},
		{ title: "Status", dataIndex: "status", key: "status" },
	];

	if (loading) {
		return <Loader />;
	}

	if (success) {
		return (
			<Success
				title={
					<p>
						Payroll Status Updated Successfully <br />
						Click <Link to="/home">Here</Link> to return Home
					</p>
				}
			/>
		);
	}

	return (
		<HeaderComponent>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<div className="payroll-table">
				<Table dataSource={payrollData} columns={payrollcolumns} />
				<Button
					className="pay-btn"
					loading={buttonLoading}
					onClick={() => setIsApprovalModalVisible(true)}>
					Approve Payroll
				</Button>
				<Button
					className="pay-btn"
					loading={buttonLoading}
					onClick={() => setIsRejectionModalVisible(true)}>
					Reject Payroll
				</Button>
			</div>

			<Modal
				title="Confirm Approval"
				open={isApprovalModalVisible}
				onOk={handleApproval}
				onCancel={() => setIsApprovalModalVisible(false)}
				okText="Approve"
				cancelText="Cancel">
				<p>Are you sure you want to approve this payroll?</p>
			</Modal>

			<Modal
				title="Confirm Rejection"
				open={isRejectionModalVisible}
				onOk={handleRejection}
				onCancel={() => setIsRejectionModalVisible(false)}
				okText="Reject"
				cancelText="Cancel">
				<p>Are you sure you want to reject this payroll?</p>
				<Input
					placeholder="Reason for rejection"
					value={rejectionReason}
					onChange={(e) => setRejectionReason(e.target.value)}
				/>
			</Modal>
		</HeaderComponent>
	);
};

export default AccountsPayroll;
