import React, { useEffect, useState } from "react";
import {
	Button,
	Modal,
	Form,
	Input,
	List,
	Typography,
	message,
	Spin,
} from "antd";
import { useAuth } from "../utils/useAuth";
import HeaderComponent from "../Header/Header";
import Main from "../Homepages/Layouts/Main";

const BroadcastMessageView = () => {
	const { apiurl, token } = useAuth();
	const [messages, setMessages] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [loadingMessages, setLoadingMessages] = useState(false); 
	const [loadingSend, setLoadingSend] = useState(false); 

	const fetchMessages = async () => {
		const childId = sessionStorage.getItem("selectedChildId");
		setLoadingMessages(true); 
		try {
			const response = await fetch(`${apiurl}/broadcast?child_id=${childId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				throw new Error("Failed to fetch messages");
			}

			const data = await response.json();
			setMessages(data.data);
		} catch (error) {
			message.error("Failed to fetch messages. Please try again later.");
		} finally {
			setLoadingMessages(false); 
		}
	};

	useEffect(() => {
		fetchMessages();
	}, []);

	const handleSendMessage = async (values) => {
		const childId = sessionStorage.getItem("selectedChildId");
		setLoadingSend(true); 
		try {
			const response = await fetch(`${apiurl}/broadcast/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					child_id: childId,
					content: values.content,
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to send message");
			}

			await response.json();
			form.resetFields();
			setIsModalVisible(false);
			message.success("Message sent successfully!");
			fetchMessages();
		} catch (error) {
			message.error("Failed to send message. Please try again.");
		} finally {
			setLoadingSend(false); 
		}
	};

	function formatDate(timestamp) {
		const date = new Date(timestamp);

		const options = {
			weekday: "short",
			year: "numeric",
			month: "long",
			day: "numeric",
		};

		let dateString = date.toLocaleDateString("en-US", options);

		const day = date.getDate();
		const suffix =
			day % 10 === 1 && day !== 11
				? "st"
				: day % 10 === 2 && day !== 12
				? "nd"
				: day % 10 === 3 && day !== 13
				? "rd"
				: "th";

		dateString = dateString.replace(/\d+/, `${day}${suffix}`);

		return dateString;
	}

	return (
		<Main>
			<div className="broadcast-container">
				<div className="broadcast-header">
					<h3 className="broadcast-title">Communications</h3>
					<Button
						type="primary"
						className="broadcast-button"
						onClick={() => setIsModalVisible(true)}>
						New Broadcast Message
					</Button>
				</div>

				{loadingMessages ? (
					<Spin size="large" tip="Loading messages..." />
				) : (
					<List
						className="broadcast-list"
						itemLayout="vertical"
						size="large"
						pagination={{ pageSize: 10 }}
						dataSource={messages}
						renderItem={({ id, sender, timestampp, content }) => (
							<List.Item key={id} className="broadcast-list-item">
								<List.Item.Meta
									title={
										<Typography.Text className="broadcast-sender">
											{sender.userName} - {formatDate(timestampp)}
										</Typography.Text>
									}
								/>
								<div
									className="broadcast-content"
									style={{ whiteSpace: "pre-wrap" }}>
									{content}
								</div>
							</List.Item>
						)}
					/>
				)}

				<Modal
					title="Send New Broadcast Message"
					open={isModalVisible}
					onCancel={() => setIsModalVisible(false)}
					footer={null}
					className="broadcast-modal">
					<Form
						form={form}
						layout="vertical"
						onFinish={handleSendMessage}
						className="broadcast-form">
						<Form.Item
							label="Content"
							name="content"
							rules={[
								{
									required: true,
									message: "Please input the message content!",
								},
							]}>
							<Input.TextArea rows={4} className="broadcast-textarea" />
						</Form.Item>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								className="broadcast-submit-button"
								loading={loadingSend} 
							>
								Send
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		</Main>
	);
};

export default BroadcastMessageView;
