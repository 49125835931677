import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Card, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";

import "./Employee.css";
import { useParams } from "react-router-dom";

const BankDetails = () => {
	const id2 = useParams();
	const [initialValues, setInitialValues] = useState({});
	const [editMode, setEditMode] = useState(false);
	const { token, apiurl } = useAuth();

	useEffect(() => {
		fetchUserDetails();
	}, []);

	const fetchUserDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/getbankdetails/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(id2),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch user details");
			}
			const data = await response.json();
			setInitialValues(data.data);
		} catch (error) {
			message.error("Error fetching user details");
		}
	};


	const renderValue = (value) => (value ? value : "N/A");

	const handleEdit = () => {
		setEditMode(true);
	};

	

	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="Bank Details"
				extra={
					<Button
						icon={<EditOutlined />}
						onClick={handleEdit}
						disabled={editMode}
					/>
				}>
				<div className="group">
					<div className="detail">
						<span>Bank Name:</span> {renderValue(initialValues.bankName)}
					</div>
					<div className="detail">
						<span>IFSC Code:</span> {renderValue(initialValues.ifsc)}
					</div>
					<div className="detail">
						<span>Account Number:</span> {renderValue(initialValues.bankAcNo)}
					</div>
				</div>
			</Card>
			
		</div>
	);
};

export default BankDetails;
