import React, { useState, useEffect } from "react";
import {
	Table,
	Button,
	Form,
	Input,
	DatePicker,
	message,
	Modal,
	Spin,
} from "antd";
import moment from "moment";
import { useAuth } from "../utils/useAuth";
import "./Resignation.css";

const ResignationView = () => {
	const { apiurl, token } = useAuth();
	const [resignations, setResignations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [formLoading, setFormLoading] = useState(false);
	const [form] = Form.useForm();

	const fetchResignations = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/resignations/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch resignation requests");
			}
			const data = await response.json();
			setResignations(data);
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = async (values) => {
		setFormLoading(true);
		try {
			const response = await fetch(`${apiurl}/resignations/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					reason: values.reason,
					noticeperiodtill: values.noticeperiodtill.format("YYYY-MM-DD"),
					handoverings: values.handoverings,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to submit resignation request");
			}
			message.success("Resignation request raised successfully");
			form.resetFields();
			setModalVisible(false);
			fetchResignations();
		} catch (error) {
			message.error(error.message);
		} finally {
			setFormLoading(false);
		}
	};

	useEffect(() => {
		fetchResignations();
	}, [apiurl, token]);

	const columns = [
		{
			title: "Timestamp",
			dataIndex: "timestamp",
			key: "timestamp",
			render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
		},
		{
			title: "Reason",
			dataIndex: "reason",
			key: "reason",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => text.charAt(0).toUpperCase() + text.slice(1),
		},
	];

	const expandedRowRender = (record) => {
		return (
			<div className="resignation-details">
				<p>
					<strong>Hand Overings:</strong>
				</p>
				<pre>{record.handoverings}</pre>
				<p>
					<strong>Notice Period Till:</strong>{" "}
					{moment(record.noticeperiodtill).format("YYYY-MM-DD")}
				</p>
				<p>
					<strong>Reporting Manager Comments:</strong>
				</p>
				<pre>{record.rm_comments || "N/A"}</pre>
				<p>
					<strong>HR Comments:</strong>
				</p>
				<pre>{record.hr_comments || "N/A"}</pre>
				<p>
					<strong>Bussiness Unit Head Comments:</strong>
				</p>
				<pre>{record.bo_comments || "N/A"}</pre>
			</div>
		);
	};

	return (
		<div className="resignation-view">
			<div className="resignation-header">
				<Button primary onClick={() => setModalVisible(true)}>
					Submit Resignation
				</Button>
			</div>

			<Table
				columns={columns}
				dataSource={resignations}
				rowKey={(record) => record.id}
				loading={loading}
				expandable={{ expandedRowRender }}
				pagination={false}
			/>

			<Modal
				title="Submit a New Resignation"
				open={modalVisible}
				onCancel={() => setModalVisible(false)}
				footer={null}
				destroyOnClose>
				<Spin spinning={formLoading}>
					<Form
						form={form}
						layout="vertical"
						onFinish={handleSubmit}
						className="resignation-form">
						<Form.Item
							label="Reason"
							name="reason"
							rules={[
								{ required: true, message: "Please input your reason!" },
							]}>
							<Input.TextArea rows={4} placeholder="Enter resignation reason" />
						</Form.Item>

						<Form.Item
							label="Notice Period Till"
							name="noticeperiodtill"
							rules={[
								{
									required: true,
									message: "Please select the notice period end date!",
								},
							]}>
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>

						<Form.Item
							label="Hand Overings"
							name="handoverings"
							rules={[
								{ required: true, message: "Please input your hand overings!" },
							]}>
							<Input.TextArea rows={4} placeholder="Enter hand overings" />
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit" loading={formLoading}>
								Submit Resignation
							</Button>
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</div>
	);
};

export default ResignationView;
