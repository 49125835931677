import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Select, message, Spin } from "antd";
import { useAuth } from "../utils/useAuth";
import HeaderComponent from "../Header/Header";

const { Option } = Select;

const UpdateResignationStatusView = () => {
	const { apiurl, token } = useAuth();
	const [resignations, setResignations] = useState([]);
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [selectedResignation, setSelectedResignation] = useState(null);

	const fetchResignations = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/resignations/hr/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch resignations");
			}
			const data = await response.json();
			setResignations(data);
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleStatusSubmit = async (values) => {
		try {
			const response = await fetch(`${apiurl}/resignations/hr/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: selectedResignation.id,
					status: values.status,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to update resignation status");
			}
			message.success("Resignation status updated successfully");
			form.resetFields();
			setSelectedResignation(null);
			fetchResignations();
		} catch (error) {
			message.error(error.message);
		}
	};

	useEffect(() => {
		fetchResignations();
	}, [apiurl, token]);

	const columns = [
		{
			title: "Timestamp",
			dataIndex: "timestamp",
			key: "timestamp",
			render: (text) => new Date(text).toLocaleString(),
			className: "hrres-timestamp-column",
		},
		{
			title: "Reason",
			dataIndex: "reason",
			key: "reason",
			className: "hrres-reason-column",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => text.charAt(0).toUpperCase() + text.slice(1),
			className: "hrres-status-column",
		},
		{
			title: "Action",
			key: "action",
			className: "hrres-action-column",
			render: (text, record) => (
				<>
					{record.status === "pending" ? (
						<Button
							type="primary"
							onClick={() => {
								setSelectedResignation(record);
								form.setFieldsValue({ status: record.status });
							}}
							className="hrres-update-status-btn">
							Update Status
						</Button>
					) : (
						<span className="hrres-status-updated">Status Already Updated</span>
					)}
				</>
			),
		},
	];

	const expandedRowRender = (record) => {
		return (
			<div className="hrres-resignation-details">
				<p>
					<strong>Handoverings:</strong>
				</p>
				<pre className="hrres-handoverings">{record.handoverings}</pre>
				<p>
					<strong>Notice Period Till:</strong>{" "}
					{new Date(record.noticeperiodtill).toLocaleDateString()}
				</p>
				<p>
					<strong>Bussiness Unit Head Comments:</strong>
				</p>
				<pre className="hrres-bo-comments">
					{record.bo_comments || "No comments"}
				</pre>
				<p>
					<strong>HR Comments:</strong>
				</p>
				<pre className="hrres-hr-comments">
					{record.hr_comments || "No comments"}
				</pre>
				<p>
					<strong>Reporting Manager Comments:</strong>
				</p>
				<pre className="hrres-rm-comments">
					{record.rm_comments || "No comments"}
				</pre>
			</div>
		);
	};

	return (
		<HeaderComponent>
			<div className="hrres-status-update-view">
				<h2 className="hrres-title">Resignation Status Update</h2>
				{loading ? (
					<Spin className="hrres-spin" />
				) : (
					<Table
						columns={columns}
						dataSource={resignations}
						rowKey={(record) => record.id}
						expandable={{
							expandedRowRender,
							rowExpandable: (record) => true,
						}}
						pagination={false}
						className="hrres-resignation-table"
					/>
				)}

				<Modal
					title="Update Status"
					visible={selectedResignation !== null}
					onCancel={() => setSelectedResignation(null)}
					footer={null}
					className="hrres-status-modal">
					<Form
						form={form}
						layout="vertical"
						onFinish={handleStatusSubmit}
						className="hrres-status-form">
						<Form.Item
							label="Status"
							name="status"
							rules={[{ required: true, message: "Please select a status!" }]}
							className="hrres-status-select-item">
							<Select
								placeholder="Select status"
								className="hrres-status-select">
								<Option value="pending">Pending</Option>
								<Option value="accepted">Accepted</Option>
								<Option value="rejected">Rejected</Option>
							</Select>
						</Form.Item>
						<Form.Item className="hrres-status-submit-item">
							<Button
								type="primary"
								htmlType="submit"
								className="hrres-status-submit-btn">
								Submit Status
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		</HeaderComponent>
	);
};

export default UpdateResignationStatusView;
