import React from "react";
import { Button, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { SafetyCertificateOutlined } from "@ant-design/icons";

const LogoutSuccess = () => {
	const navigate = useNavigate();

	const handleLoginRedirect = () => {
		navigate("/login");
	};

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				background: "linear-gradient(135deg, #e6f7ff 0%, #cceeff 100%)",
				padding: "20px",
			}}>
			<Card
				style={{
					width: "90%",
					maxWidth: "500px",
					borderRadius: "16px",
					boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
					textAlign: "center",
					padding: "30px",
				}}>
				<SafetyCertificateOutlined
					style={{
						fontSize: "64px",
						color: "#52c41a",
						animation: "bounce 1s infinite",
					}}
				/>
				<h2
					style={{
						fontSize: "28px",
						fontWeight: "bold",
						color: "#333",
						margin: "20px 0",
					}}>
					Logout Successful!
				</h2>
				<p style={{ fontSize: "16px", color: "#666", marginBottom: "30px" }}>
					You've logged out successfully. We look forward to seeing you again!
					Please click the button below to log in again.
				</p>
				<Button
					type="primary"
					size="large"
					onClick={handleLoginRedirect}
					style={{
                        backgroundColor : "var(--color-primary)",
						borderRadius: "8px",
						transition: "all 0.3s ease",
						fontSize: "16px",
						padding: "10px 20px",
						boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
					}}
					onMouseEnter={(e) => {
						e.currentTarget.style.backgroundColor = "var(--color-primary)";
						e.currentTarget.style.transform = "scale(1.05)";
						e.currentTarget.style.boxShadow = "0 8px 24px rgba(0, 0, 0, 0.2)";
					}}
					onMouseLeave={(e) => {
						e.currentTarget.style.backgroundColor = "";
						e.currentTarget.style.transform = "";
						e.currentTarget.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.1)";
					}}>
					Log In Again
				</Button>
			</Card>

			<style>{`
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-10px);
          }
          60% {
            transform: translateY(-5px);
          }
        }
      `}</style>
		</div>
	);
};

export default LogoutSuccess;
