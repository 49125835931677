import { useAuth } from "../../utils/useAuth";
import { message, Table, Button } from "antd";
import { useParams, Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import HeaderComponent from "../../Header/Header";
import {  LeftCircleOutlined } from "@ant-design/icons";

const EmployeeData = () => {
	const navigate = useNavigate();

	
	useEffect(() => {
		fetchEmpData();
	}, []);

	const { month, year } = useParams();
	const [employees, SetEmployees] = useState(null);
	const { apiurl, token } = useAuth();

	
	const columns = [
		{
			title: "Name",
			dataIndex: "userName",
			key: "userName",
			render: (text, record) => (
				<>
					<Link to={`/employees/${record.id}`}>{text}</Link>
				</>
			),
		},
		{
			title: "Date of Joining",
			dataIndex: "dateOfJoining",
			key: "dateOfJoining",
		},
		{
			title: "Department",
			dataIndex: "department",
			key: "department",
		},
		{
			title: "Designation",
			dataIndex: "designation",
			key: "designation",
		},
		{
			title: "CTC",
			dataIndex: "ctc",
			key: "ctc",
		},
		{
			title: "Present",
			dataIndex: "present",
			key: "present",
		},
		{
			title: "Half Days",
			dataIndex: "halfday",
			key: "halfday",
		},
		{
			title: "Leaves",
			dataIndex: "leaves",
			key: "leaves",
		},
		{
			title: "Absent",
			dataIndex: "absent",
			key: "absent",
		},
		{
			title: "Late",
			dataIndex: "latelogin",
			key: "latelogin",
		},
		{
			title: "Carry Forwarded Leave Balance",
			dataIndex: "carryleavebal",
			key: "carryleavebal",
		},
		{
			title: "Current Leave Balance",
			dataIndex: "currentleavebal",
			key: "currentleavebal",
		},
	];

	
	const fetchEmpData = async () => {
		try {
			const response = await fetch(`${apiurl}/empdataforrm/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
					month: month,
					year: year,
				}),
			});

			const data = await response.json();
			if (response.ok) {
				SetEmployees(data);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("An error occurred while processing your request.");
		}
	};

	return (
		<HeaderComponent>
			<div className="empdata-rm-container">
				<div className="go-back">
					<Button type="text" onClick={() => navigate(-1)}>
						<LeftCircleOutlined />
						Go back
					</Button>
				</div>
				<div className="empdata-rm-header">
					<h1 className="empdata-rm-title">Employee Data</h1>
				</div>
				<div className="empdata-rm-table-container">
					<Table
						dataSource={employees}
						columns={columns}
						rowKey="id"
						className="empdata-rm-table"
					/>
				</div>
			</div>
		</HeaderComponent>
	);
};

export default EmployeeData;
