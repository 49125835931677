import React, { useEffect, useState } from "react";
import {
	Table,
	Button,
	Modal,
	Form,
	Input,
	Select,
	message,
	Space,
} from "antd";
import { useAuth } from "../utils/useAuth";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./Assets.css";
import HeaderComponent from "../Header/Header";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const AssetsView = () => {
	const { apiurl, token } = useAuth();
	const childId = sessionStorage.getItem("selectedChildId");
	const [assets, setAssets] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm();
	const [editingAsset, setEditingAsset] = useState(null);
    const navigate = useNavigate();

	useEffect(() => {
		fetchAssets();
		fetchDepartments();
	}, []);

	const fetchAssets = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/assets?child_id=${childId}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setAssets(data);
		} catch (error) {
			message.error("Failed to fetch assets.");
		}
		setLoading(false);
	};

	const fetchDepartments = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchdepartments/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ childid: childId }),
			});
			const data = await response.json();
			setDepartments(data.data);
		} catch (error) {
			message.error("Failed to fetch departments.");
		}
	};

	const handleSubmit = async (values) => {
		const url = editingAsset ? `${apiurl}/assets/` : `${apiurl}/assets/`;
		const method = editingAsset ? "PUT" : "POST";
		const assetData = {
			...values,
			child_id: childId,
			id: editingAsset?.id,
		};

		try {
			const response = await fetch(url, {
				method,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(assetData),
			});

			const result = await response.json();

			if (response.ok) {
				message.success(
					editingAsset
						? "Asset updated successfully"
						: "Asset added successfully"
				);
				fetchAssets();
				setVisible(false);
				form.resetFields();
			} else {
				message.error(result.error || "An error occurred");
			}
		} catch (error) {
			message.error("Failed to submit asset.");
		}
	};

	const handleDelete = async (id) => {
		try {
			const response = await fetch(`${apiurl}/assets/`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ id }),
			});

			if (response.ok) {
				message.success("Asset deleted successfully");
				fetchAssets();
			} else {
				const result = await response.json();
				message.error(result.error || "Failed to delete asset");
			}
		} catch (error) {
			message.error("Failed to delete asset.");
		}
	};

	const openModal = (asset = null) => {
		setEditingAsset(asset);
		setVisible(true);
		if (asset) {
			form.setFieldsValue({
				name: asset.name,
				dep_id: asset.department?.id,
			});
		} else {
			form.resetFields();
		}
	};

	const groupedAssets = departments.map((department) => ({
		department,
		assets: assets.filter((asset) => asset.department.id === department.id),
	}));

	const renderTables = () => {
		return groupedAssets.map(({ department, assets }) => (
			<div key={department.id} className="assets_main__table-wrapper">
				<h2 className="assets_main__table-title">{department.name}</h2>
				<Table
					dataSource={assets}
					columns={[
						{
							title: "Name",
							dataIndex: "name",
							key: "name",
						},
						{
							title: "Actions",
							key: "actions",
							render: (text, record) => (
								<Space>
									<Button primary onClick={() => openModal(record)}>
										Edit
									</Button>
									<Button danger onClick={() => handleDelete(record.id)}>
										Delete
									</Button>
									<Button success onClick={() => navigate(`${record.id}`)}>
										View all
									</Button>
								</Space>
							),
						},
					]}
					rowKey="id"
					pagination={false}
					className="assets_main__table"
				/>
			</div>
		));
	};

	const handleBackClick = () => {
		window.history.back();
	};

	return (
		<HeaderComponent>
			<div className="assets_main__container">
				<div className="assets_main__header">
					<Button
						className="assets_main__back-button"
						icon={<ArrowLeftOutlined />}
						onClick={handleBackClick}>
						Back
					</Button>
					<h1 className="assets_main__title">Assets by Department</h1>
				</div>
				<Button
					onClick={() => openModal()}
					className="assets_main__add-button">
					Add Asset
				</Button>
				{loading ? <p>Loading...</p> : renderTables()}

				<Modal
					title={editingAsset ? "Edit Asset" : "Add Asset"}
					open={visible}
					onCancel={() => setVisible(false)}
					onOk={() => form.submit()}
					className="assets_main__modal">
					<Form form={form} layout="vertical" onFinish={handleSubmit}>
						<Form.Item
							label="Asset Name"
							name="name"
							rules={[{ required: true, message: "Please enter asset name" }]}
							className="assets_main__form-item">
							<Input />
						</Form.Item>
						<Form.Item
							label="Department"
							name="dep_id"
							rules={[
								{ required: true, message: "Please select a department" },
							]}
							className="assets_main__form-item">
							<Select placeholder="Select Department">
								{departments.map((dep) => (
									<Option key={dep.id} value={dep.id}>
										{dep.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		</HeaderComponent>
	);
};

export default AssetsView;
