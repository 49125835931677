import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Card, message, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";

import "./Employee.css";

const { Option } = Select;

const BasicDetailsModal = () => {
	const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [editMode, setEditMode] = useState(false);
	const { token, apiurl } = useAuth();

	useEffect(() => {
		fetchUserDetails();
	}, []);

	const fetchUserDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/basicdetails/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch user details");
			}
			const data = await response.json();
			setInitialValues(data.data);
		} catch (error) {
			message.error("Error fetching user details");
		}
	};

	const handleUpdate = async (values) => {
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/basicdetails/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details updated successfully");
				setEditMode(false);
				fetchUserDetails();
			} else {
				message.error("Failed to update details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error updating details");
			setLoading(false);
		}
	};

	const renderValue = (value) => (value ? value : "N/A");

	const handleEdit = () => {
		setEditMode(true);
	};

	const handleCancelEdit = () => {
		setEditMode(false);
	};

	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="Basic Details"
				extra={
					<Button
						icon={<EditOutlined />}
						onClick={handleEdit}
						disabled={editMode}
					/>
				}>
				<div className="group">
					<div className="detail">
						<span>First Name:</span> {renderValue(initialValues.firstName)}
					</div>
					<div className="detail">
						<span>Middle Name:</span> {renderValue(initialValues.middleName)}
					</div>
					<div className="detail">
						<span>Last Name:</span> {renderValue(initialValues.lastName)}
					</div>
				</div>
				<div className="detail">
					<span>Communication Address: </span>
					{renderValue(initialValues.communicationAddress)}
				</div>
				<div className="detail">
					<span>Permanent Address:</span>{" "}
					{renderValue(initialValues.permanentAddress)}
				</div>

				<div className="group">
					<div className="detail">
						<span>Aadhar Number:</span>{" "}
						{renderValue(initialValues.aadharNumber)}
					</div>
					<div className="detail">
						<span>PAN Card Number:</span>{" "}
						{renderValue(initialValues.panCardNumber)}
					</div>
				</div>

				<div className="group">
					<div className="detail">
						<span>Blood Group:</span> {renderValue(initialValues.bloodGroup)}
					</div>
					<div className="detail">
						<span>Health Insurance Number: </span>
						{renderValue(initialValues.healthInsuranceNumber)}
					</div>
				</div>

				<div className="group">
					<div className="detail">
						<span>UAN Number:</span>{" "}
						{renderValue(initialValues.pfAccountNumber)}
					</div>
					<div className="detail">
						<span>ESIC Number:</span>{" "}
						{renderValue(initialValues.esiAccountNumber)}
					</div>
					<div className="detail">
						<span>Gratuity Number:</span>{" "}
						{renderValue(initialValues.gratuityNumber)}
					</div>
				</div>
				<div className="detail">
					<span>Health Issues:</span> {renderValue(initialValues.healthIssues)}
				</div>
			</Card>
			<Modal
				open={editMode}
				title="Edit Basic Details"
				onCancel={handleCancelEdit}
				footer={[
					<Button key="cancel" onClick={handleCancelEdit}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					initialValues={initialValues}
					onFinish={handleUpdate}>
					<Form.Item name="firstName" label="First Name">
						<Input />
					</Form.Item>
					<Form.Item name="lastName" label="Last Name">
						<Input />
					</Form.Item>
					<Form.Item name="middleName" label="Middle Name">
						<Input />
					</Form.Item>
					<Form.Item name="communicationAddress" label="Communication Address">
						<Input.TextArea />
					</Form.Item>
					<Form.Item name="permanentAddress" label="Permanent Address">
						<Input.TextArea />
					</Form.Item>
					<Form.Item name="aadharNumber" label="Aadhar Number">
						<Input />
					</Form.Item>
					<Form.Item name="panCardNumber" label="PAN Card Number">
						<Input />
					</Form.Item>
					<Form.Item name="bloodGroup" label="Blood Group">
						<Select>
							{bloodGroups.map((group) => (
								<Option key={group} value={group}>
									{group}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="healthIssues" label="Health Issues">
						<Input.TextArea />
					</Form.Item>
					<Form.Item name="pfAccountNumber" label="UAN Number">
						<Input />
					</Form.Item>
					<Form.Item name="gratuityNumber" label="Gratuity Number">
						<Input />
					</Form.Item>
					<Form.Item name="esiAccountNumber" label="ESIC Number">
						<Input />
					</Form.Item>
					<Form.Item
						name="healthInsuranceNumber"
						label="Health Insurance Number">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default BasicDetailsModal;
