import React, { useState, useEffect } from "react";
import { useAuth } from "../utils/useAuth";
import {
	Button,
	Input,
	message,
	Modal,
	Popconfirm,
	Table,
	Select,
	Progress,
} from "antd";
import "./Punch.css";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const Punch = () => {
	const { apiurl, token, handleLogout } = useAuth();
	const navigate = useNavigate();
	const [punchData, setPunchData] = useState(null);
	const [breakData, setBreakData] = useState(null);
	const [objStatus, setObjStatus] = useState(false);
	const [breakobjStatus, setBreakObjStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const [publicIP, setPublicIP] = useState("");
	const [punchedout, setPunchedout] = useState(false);
	const [punchedin, setPunchedin] = useState(false);
	const [reason, setReason] = useState("");
	const [isopen, setIsopen] = useState(false);
	const [isOther, setIsOther] = useState(false);
	const [showBreakModal, setShowBreakModal] = useState(false);
	const [workingHours,setWorkingHours] = useState(null);

	useEffect(() => {
		fetchAttt();
		getatt();
		fetchBreak();
		calculateHours();
	}, []);

	useEffect(() => {
		const fetchPublicIP = async () => {
			try {
				const response = await fetch("https://api.ipify.org?format=json");
				const data = await response.json();
				setPublicIP(data.ip);
			} catch (error) {
				console.error("Error fetching public IP:", error);
			}
		};

		fetchPublicIP();
	}, []);

	const fetchAttt = async () => {
		try {
			const response = await fetch(`${apiurl}/punchattendance`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setPunchData(data.attendance);
				setObjStatus(true);
			} else {
				setObjStatus(false);
			}
		} catch (error) {
			console.error("Error during Fetch Attendance:", error);
			setObjStatus(false);
		}
	};

	const submitPunchIn = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/punchattendance/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
					type: "PUNCHIN",
					ip: publicIP,
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				window.location.reload();
				fetchAttt();
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error during Punch In");
		} finally {
			setLoading(false);
		}
	};

	const submitPunchOut = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/punchattendance/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: punchData.id,
					type: "PUNCHOUT",
					ip: publicIP,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			const data = await response.json();
			if (response.ok) {
				fetchAttt();
				window.location.reload();
				setTimeout(() => {
					Logout();
				}, 3 * 60 * 1000);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error during Punch Out");
		} finally {
			setLoading(false);
		}
	};

	const Logout = () => {
		handleLogout();
		message.success("Logout successful");
		navigate("/logout");
	};

	const formatTime = (totalSeconds) => {
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = totalSeconds % 60;
		return `${hours.toString().padStart(2, "0")}Hr ${minutes
			.toString()
			.padStart(2, "0")}Min`;
	};

	const calculateTotalBreakTime = () => {
		if (!breakData || breakData.length === 0) return formatTime(0);
		let totalSeconds = 0;

		breakData.forEach((breakEntry) => {
			if (breakEntry.time_out && breakEntry.time_in) {
				const timeInParts = breakEntry.time_in.split(":").map(Number);
				const timeOutParts = breakEntry.time_out.split(":").map(Number);
				const timeIn = new Date();
				timeIn.setHours(timeInParts[0], timeInParts[1], timeInParts[2]);
				const timeOut = new Date();
				timeOut.setHours(timeOutParts[0], timeOutParts[1], timeOutParts[2]);
				totalSeconds += (timeOut - timeIn) / 1000;
			}
		});

		return formatTime(totalSeconds);
	};


	const calculateHours = () => {
		if (!punchData || !punchData.time_in) {
			return setWorkingHours(formatTime(0));
		}

		if (punchData.time_out) {
			const [hours, minutes, seconds] = punchData.time_in.split(":");
			const punchInTime = new Date();
			punchInTime.setHours(hours, minutes, seconds);

			const [hours1, minutes1, seconds1] = punchData.time_out.split(":");
			const punchOutTime = new Date();
			punchOutTime.setHours(hours1, minutes1, seconds1);

			const diffMs = punchOutTime - punchInTime;
			const diffSeconds = Math.floor(diffMs / 1000);

			return setWorkingHours(formatTime(diffSeconds));
		}

		const [hours, minutes, seconds] = punchData.time_in.split(":");
		const punchInTime = new Date();
		punchInTime.setHours(hours, minutes, seconds);

		const currentTime = new Date();
		const diffMs = currentTime - punchInTime;
		const diffSeconds = Math.floor(diffMs / 1000);
		return setWorkingHours(formatTime(diffSeconds));
	};


	setInterval(calculateHours, 1000);

	
	const handleReasonChange = (value) => {
		setReason(value);
		setIsOther(value === "Other");
	};

	const reqbutton = () => {
		if (punchedin) {
			if (breakobjStatus && breakData) {
				if (breakData.length > 0 && !breakData[breakData.length - 1].time_out) {
					return (
						<Button
							className="breakbtn"
							onClick={submitBreakOut}
							loading={loading}
							disabled={loading}>
							Break out
						</Button>
					);
				} else if (!punchedout) {
					return (
						<Button
							className="breakbtn"
							onClick={() => setIsopen(true)}
							loading={loading}
							disabled={loading}>
							Break In
						</Button>
					);
				}
			}
		}
		return null;
	};

	const fetchBreak = async () => {
		try {
			const response = await fetch(`${apiurl}/addbreak`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setBreakData(data.break);
				setBreakObjStatus(true);
			} else {
				setBreakObjStatus(false);
			}
		} catch (error) {
			console.error("Error during Fetch Break Data:", error);
			setBreakObjStatus(false);
		}
	};

	const getatt = async () => {
		try {
			const response = await fetch(`${apiurl}/checktimeout`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setPunchedout(data.punchedout);
				setPunchedin(data.punchedin);
			}
		} catch (error) {
			console.error("Error during Fetch Attendance:", error);
			setPunchedout(false);
			setPunchedin(false);
			setBreakObjStatus(false);
		}
	};

	const submitBreakIn = async () => {
		if (reason === "" || !reason) {
			message.error("Please Mention Reason");
			return;
		}
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/addbreak/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
					type: "BREAKIN",
					ip: publicIP,
					reason: reason,
				}),
			});
			const data = await response.json();
			if (response.ok) {
				fetchBreak();
				message.success("Break In successful");
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error during Punching Break In");
		} finally {
			setLoading(false);
			setIsopen(false);
			setIsOther(false);
			setReason("");
		}
	};

	const submitBreakOut = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/addbreak/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: breakData[breakData.length - 1].id,
					type: "BREAKOUT",
					ip: publicIP,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				fetchBreak();
				message.success("Break Out successful");
			} else {
				message.error("Error during Punching Break Out");
			}
		} catch (error) {
			message.error("Error during Punching Break Out");
		} finally {
			setLoading(false);
			setShowBreakModal(false);
		}
	};
	const formatBreakTime = (timeStr) => {
		if (!timeStr) {
			return "";
		}
		const [hours, minutes] = timeStr.split(":").map(Number);
		if (hours === 0) {
			return `${minutes}mins`;
		} else {
			return `${hours}hr ${minutes}mins`;
		}
	};

	const columns = [
		{
			title: "Reason",
			dataIndex: "reason",
			key: "reason",
		},
		{
			title: "In",
			dataIndex: "time_in",
			key: "time_in",
		},
		{
			title: "Out",
			dataIndex: "time_out",
			key: "time_out",
		},
		{
			title: "Duration",
			dataIndex: "net_time_in",
			key: "net_time_in",
			render: (text) => formatBreakTime(text),
		},
	];

	return (
		<div className="punch-main">
			<div className="heading">Today's Insights</div>
			<div className="todays-insights">
				<div className="working-breaktimes">
					<div className="punch-item">
						{punchData && punchData.time_in && (
							<div className="item-title">Clock In Time:</div>
						)}{" "}
						<div className="item-value">
							{punchData && punchData.time_in ? (
								punchData.time_in
							) : (
								<div className="qt">
									Your Presence Matters - Mark Your Attendance!
								</div>
							)}
						</div>
					</div>
					<div
						className="punch-item"
						onClick={() => setShowBreakModal(true)}
						style={{ cursor: "pointer" }}>
						<div className="item-title">Total Break Time:</div>{" "}
						<div className="item-value">{calculateTotalBreakTime()}</div>
					</div>
				</div>
				<div className="punch-item">
					<div className="item-title">Working Hours:</div> <br></br>
					<Progress
						type={"circle"}
						percent={100}
						format={() => `${workingHours}`}
					/>
				</div>
			</div>
			{objStatus && punchData && (
				<div className="punch-out-time">
					{punchData.time_out && (
						<div
							className="punch-item"
							onClick={() => setShowBreakModal(true)}
							style={{ cursor: "pointer" }}>
							<div className="item-title">Clock Out Time:</div>{" "}
							<div className="item-value">{punchData.time_out}</div>
						</div>
					)}
				</div>
			)}
			<div>
				{objStatus && punchData ? (
					!punchData.time_in ? (
						<Popconfirm
							className="punchBtn"
							title="Are you sure to clock in?"
							onConfirm={submitPunchIn}
							okText="Yes"
							cancelText="No">
							<Button className="Punchbtn" loading={loading} disabled={loading}>
								Clock-In
							</Button>
						</Popconfirm>
					) : (
						!punchData.time_out && (
							<div className="punch-action-btns-inner">
								<div>
									<Popconfirm
										title="Are you sure to clock out?"
										onConfirm={submitPunchOut}
										okText="Yes"
										cancelText="No">
										<Button
											danger
											className="Punchbtn bg-red"
											loading={loading}
											disabled={loading}>
											Clock-Out
										</Button>
									</Popconfirm>
								</div>
								<div>{reqbutton()}</div>
							</div>
						)
					)
				) : (
					<Popconfirm
						title="Are you sure to clock in?"
						onConfirm={submitPunchIn}
						okText="Yes"
						cancelText="No">
						<Button className="Punchbtn" loading={loading} disabled={loading}>
							Clock-In
						</Button>
					</Popconfirm>
				)}
			</div>
			<Modal
				title="Break Reason"
				className="brean-reason-form"
				open={isopen}
				onCancel={() => {
					setReason("");
					setIsopen(false);
				}}
				footer={[
					<Button
						key="cancel"
						onClick={() => {
							setReason("");
							setIsopen(false);
						}}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={submitBreakIn}>
						Take Break
					</Button>,
				]}>
				<Select value={reason} onChange={handleReasonChange}>
					<Option value="Lunch">Lunch</Option>
					<Option value="Tea">Tea</Option>
					<Option value="Other">Other</Option>
				</Select>
				{isOther && (
					<Input
						style={{ marginTop: 10 }}
						value={reason}
						onChange={(e) => setReason(e.target.value)}
						placeholder="Please specify reason"
					/>
				)}
			</Modal>

			<Modal
				title="Break Data"
				open={showBreakModal}
				onCancel={() => setShowBreakModal(false)}
				footer={null}
				className="break-data-modal">
				<Table
					dataSource={breakData}
					columns={columns}
					rowKey={(record) => record.id}
					pagination={false}
					className="break-data-table"
				/>
			</Modal>
		</div>
	);
};

export default Punch;
