import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Card, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";

import "./Employee.css";


const BankDetails = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [editMode, setEditMode] = useState(false);
	const { token, apiurl } = useAuth();

	useEffect(() => {
		fetchUserDetails();
	}, []);

	const fetchUserDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/bankdetails/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch user details");
			}
			const data = await response.json();
			setInitialValues(data.data);
		} catch (error) {
			message.error("Error fetching user details");
		}
	};

	const handleUpdate = async (values) => {
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/bankdetails/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details updated successfully");
				setEditMode(false);
				fetchUserDetails();
			} else {
				message.error("Failed to update details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error updating details");
			setLoading(false);
		}
	};

	const renderValue = (value) => (value ? value : "N/A");

	const handleEdit = () => {
		setEditMode(true);
	};

	const handleCancelEdit = () => {
		setEditMode(false);
	};

	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="Bank Details"
				extra={
					<Button
						icon={<EditOutlined />}
						onClick={handleEdit}
						disabled={editMode}
					/>
				}>
				<div className="group">
					<div className="detail">
						<span>Bank Name:</span> {renderValue(initialValues.bankName)}
					</div>
					<div className="detail">
						<span>IFSC Code:</span> {renderValue(initialValues.ifsc)}
					</div>
					<div className="detail">
						<span>Account Number:</span> {renderValue(initialValues.bankAcNo)}
					</div>
				</div>
			</Card>
			<Modal
				open={editMode}
				title="Edit Bank Details"
				onCancel={handleCancelEdit}
				footer={[
					<Button key="cancel" onClick={handleCancelEdit}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					initialValues={initialValues}
					onFinish={handleUpdate}>
					<Form.Item name="bankName" label="Bank Name">
						<Input />
					</Form.Item>
					<Form.Item name="ifsc" label="IFSC Code">
						<Input />
					</Form.Item>
					<Form.Item name="bankAcNo" label="Account Number">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default BankDetails;
