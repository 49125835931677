import React, { useEffect, useState } from "react";
import {
	Form,
	Input,
	Button,
	Select,
	message,
	Steps,
	Row,
	Col,
	Card,
	Modal,
} from "antd";
import { useAuth } from "../utils/useAuth";
import Success from "../utils/Success";
import moment from "moment";
import Loader from "../Loader/Loader";
import { useParams } from "react-router-dom";
import HeaderComponent from "../Header/Header";
import "./Employee.css";

const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;

const OnboardEmployee = () => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [departments, setDepartments] = useState([]);
	const [designations, setDesignations] = useState([]);
	const [allowances, setAllowances] = useState([]);
	const { role, child_id } = useParams();
	const [employees, setEmployees] = useState([]);
	const [currentStep, setCurrentStep] = useState(0);
	const [visible, setVisible] = useState(false);
	const [step1Data, setStep1Data] = useState({});

	const [popupForm] = Form.useForm();

	useEffect(() => {
		if (role !== "BUSINESS_OWNER" && role !== "HR_HEAD") {
			fetchDepartments();
			fetchDesignations();
			fetchEmployees();
		}
		fetchAllowances();
	}, [role, child_id]);

	useEffect(() => {
		const ctcValue = form.getFieldValue("ctc");
		if (ctcValue) {
			if (ctcValue < 360000) {
				const autoBasic = (ctcValue / 12) * 0.5;
				form.setFieldsValue({
					basic: autoBasic,
					basic_type: "amount",
				});
			}
		}
	}, [form]);

	const fetchAllowances = async () => {
		try {
			const response = await fetch(
				`${apiurl}/allowances/?child_id=${child_id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setAllowances(data.data);
			} else {
				message.error("Unable to fetch allowances. Please try again.");
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		}
	};

	const fetchEmployees = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchemployees/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setEmployees(data.data);
			} else {
				message.error("Unable to fetch employees. Please try again.");
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		}
	};

	const fetchDepartments = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchdepartments/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setDepartments(data.data);
			} else {
				message.error("Unable to fetch departments. Please try again.");
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		}
	};

	const fetchDesignations = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchdesignations/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setDesignations(data.data);
			} else {
				message.error("Unable to fetch designations. Please try again.");
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		}
	};

	const calculateRemainingCTC = async () => {
		const ctc = form.getFieldValue("ctc");
		if (!ctc) {
			message.error("Please input CTC before calculating.");
			return;
		}

		const autoBasic = Math.round((ctc / 12) * 0.5);
		popupForm.setFieldsValue({
			basic: autoBasic,
			basic_type: "amount",
			pf: autoBasic*0.12,
		});
		setVisible(true);
	};

	const handlePopupOk = async () => {
		const values = await popupForm.validateFields();
		const { basic, basic_type, pf } = values;

		if (!basic || !basic_type || !pf) {
			message.error("Please fill all the fields before calculating.");
			return;
		}

		try {
			const response = await fetch(`${apiurl}/balanceallowances/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					ctc: form.getFieldValue("ctc"),
					basic,
					basic_type,
					child_id,
					pf,
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setAllowances(
					Object.entries(data.Allowances).map(([key, value]) => ({
						name: key,
						value,
					}))
				);

				Object.entries(data.Allowances).forEach(([key, value]) => {
					form.setFieldsValue({
						[key]: value,
					});
				});

				form.setFieldsValue({
					pfDeduction: data.Deductions.PF,
					esiDeduction: data.Deductions.ESI || 0,
					grossSalary: data.FinalSummary.GrossSalary,
					totalDeductions: data.Deductions.Total,
					finalPF: data.FinalSummary.FinalPF,
					basicSalary: data.FinalSummary.BasicSalary,
					totalAllowances: data.FinalSummary.Sumofallallowances,
				});

				message.success("Remaining CTC calculated successfully.");
				setVisible(false);
			} else {
				message.error("Unable to calculate remaining CTC.");
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		}
	};

	const onFinish = async () => {
		try {
			const step1Values = step1Data;
			const step2Values = form.getFieldsValue();

			const combinedValues = { ...step1Values, ...step2Values };
			setLoading(true);

			
			combinedValues.dateOfBirth = moment(combinedValues.dateOfBirth).format("YYYY-MM-DD");
			combinedValues.dateOfJoining = moment(combinedValues.dateOfJoining).format("YYYY-MM-DD");
			
			const allowancesData = allowances.map((allowance) => ({
				name: allowance.name,
				value: combinedValues[allowance.name], 
			}));

			combinedValues.role = role;
			combinedValues.childId = child_id;
			combinedValues.allowances = allowancesData;

			const response = await fetch(`${apiurl}/onboardemployee/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(combinedValues),
			});

			const data = await response.json();
			if (response.ok) {
				setIsSuccess(true);
				message.success(data.message);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error(`Error: ${error.message}`);
		} finally {
			setLoading(false);
		}
	};

	const nextStep = async () => {
		try {
			await form.validateFields();
			const step1Values = await form.validateFields();
			setStep1Data(step1Values); 
			setCurrentStep((prev) => prev + 1);
		} catch (error) {
			message.error("Please fill all the required fields before proceeding.");
		}
	};
	const prevStep = () => setCurrentStep((prev) => prev - 1);

	if (loading) {
		return <Loader />;
	}

	if (isSuccess) {
		return <Success title={`${role} Added Successfully`} />;
	}

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.basic) {
			const basicValue = changedValues.basic;
			
			const pfValue = (basicValue * 12) / 100;
			
			popupForm.setFieldsValue({
				pf: pfValue,
			});
		}
	};

	return (
		<HeaderComponent>
			<div className="add-employee-cont">
				<Form
					className="add-emp-form"
					form={form}
					layout="vertical"
					initialValues={{ remember: true }}
					onFinish={onFinish}>
					{currentStep === 0 && (
						<Card title="Basic Information" className="form-card">
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name="empid"
										label="Employee ID"
										rules={[
											{ required: true, message: "Please input Employee ID" },
										]}>
										<Input />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="userName"
										label="Name of the Employee"
										rules={[
											{
												required: true,
												message: "Please input the Employee's name",
											},
										]}>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name="email"
										label="Email"
										rules={[
											{
												required: true,
												type: "email",
												message: "Please input a valid email",
											},
										]}>
										<Input type="email" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="dateOfBirth"
										label="Date of Birth"
										rules={[
											{
												required: true,
												message: "Please select Date of Birth",
											},
										]}>
										<Input type="date" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name="phoneNumber"
										label="Phone Number"
										rules={[
											{ required: true, message: "Please input Phone Number" },
										]}>
										<Input />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="type"
										label="Type"
										rules={[
											{
												required: true,
												message: "Please select Employee Type",
											},
										]}>
										<Select>
											<Option value="part_time">Part Time</Option>
											<Option value="full_time">Full Time</Option>
											<Option value="contract">Contract</Option>
											<Option value="other">Other</Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name="gender"
										label="Gender"
										rules={[
											{ required: true, message: "Please select Gender" },
										]}>
										<Select>
											<Option value="male">Male</Option>
											<Option value="female">Female</Option>
											<Option value="other">Other</Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={24}>
									<Form.Item
										name="jobdescription"
										label="Job Description"
										rules={[
											{
												required: true,
												message: "Please input Job Description",
											},
										]}>
										<TextArea rows={4} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={24}>
									<Form.Item
										name="kras"
										label="KRAs"
										rules={[{ required: true, message: "Please input KRAs" }]}>
										<TextArea rows={4} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={24}>
									<Form.Item
										name="careerpath"
										label="Career Path"
										rules={[
											{ required: true, message: "Please input Career Path" },
										]}>
										<TextArea rows={4} />
									</Form.Item>
								</Col>
							</Row>
							{role !== "BUSINESS_OWNER" && role !== "HR_HEAD" && (
								<>
									<Row gutter={16}>
										<Col span={12}>
											<Form.Item
												name="designation"
												label="Designation"
												rules={[
													{
														required: true,
														message: "Please select Designation",
													},
												]}>
												<Select>
													{designations.map((designation) => (
														<Option key={designation.id} value={designation.id}>
															{designation.name}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												name="department"
												label="Department"
												rules={[
													{
														required: true,
														message: "Please select Department",
													},
												]}>
												<Select>
													{departments.map((department) => (
														<Option key={department.id} value={department.id}>
															{department.name}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col span={24}>
											<Form.Item
												name="reported_to"
												label="Reported To"
												rules={[
													{
														required: true,
														message: "Please select Reporting Person",
													},
												]}>
												<Select>
													{employees.map((employee) => (
														<Option key={employee.id} value={employee.id}>
															{employee.userName}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									</Row>
								</>
							)}
							<Form.Item className="form-buttons">
								<Button type="primary" onClick={nextStep}>
									Next
								</Button>
							</Form.Item>
						</Card>
					)}

					{currentStep === 1 && (
						<Card title="Compensation Details" className="form-card">
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name="ctc"
										label="CTC"
										rules={[{ required: true, message: "Please input CTC" }]}>
										<Input type="number" />
									</Form.Item>
								</Col>
								<Col
									span={12}
									style={{ display: "flex", alignItems: "center" }}>
									<Button type="primary" onClick={calculateRemainingCTC}>
										Calculate Remaining
									</Button>
								</Col>
							</Row>

							{allowances.length > 0 && (
								<>
									<h3>Allowances</h3>
									<>
										{allowances.map((allowance) => (
											<Col key={allowance.name} span={12}>
												<Form.Item
													name={allowance.name}
													label={allowance.name}
													initialValue={allowance.value}>
													<Input type="number" />
												</Form.Item>
											</Col>
										))}
									</>
								</>
							)}

							<Row gutter={16}>
								<Col span={12}>
									<h3>Employer Deductions</h3>
									<Form.Item name="pfDeduction" label="PF Deduction">
										<Input type="number" />
									</Form.Item>
									<Form.Item name="esiDeduction" label="ESI Deduction">
										<Input type="number" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<h3>Final Summary</h3>
									<Form.Item name="grossSalary" label="Gross Salary">
										<Input type="number" />
									</Form.Item>
									<Form.Item name="basicSalary" label="Basic Salary">
										<Input type="number" />
									</Form.Item>
									<Form.Item name="totalAllowances" label="Total Allowances">
										<Input type="number" />
									</Form.Item>
									<Form.Item name="totalDeductions" label="Total Deductions">
										<Input type="number" />
									</Form.Item>
								</Col>
							</Row>

							<Form.Item className="form-buttons">
								<div
									style={{ display: "flex", justifyContent: "space-between" }}>
									<Button type="default" onClick={prevStep}>
										Previous
									</Button>
									<Button type="primary" htmlType="submit">
										Create Employee
									</Button>
								</div>
							</Form.Item>
						</Card>
					)}
				</Form>

				<Modal
					title="Calculate Remaining CTC"
					open={visible}
					okText="Calculate"
					cancelText="Cancel"
					footer={false}
					onCancel={() => setVisible(false)}
					onOk={handlePopupOk}>
					<Form
						form={popupForm}
						onValuesChange={onValuesChange}
						layout="vertical">
						<Form.Item name="basic" label="Basic">
							<Input type="number" />
						</Form.Item>
						<Form.Item name="basic_type" label="Basic Type">
							<Select>
								<Option value="percent">Percent</Option>
								<Option value="amount">Amount</Option>
							</Select>
						</Form.Item>
						<Form.Item name="pf" label="PF">
							<Input type="number" />
						</Form.Item>
						<br></br>
						<Form.Item>
							<Button type="primary" onClick={handlePopupOk}>
								Calculate
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		</HeaderComponent>
	);
};

export default OnboardEmployee;
