import React, { useEffect, useState } from "react";
import HeaderComponent from "../Header/Header";
import { Button, Checkbox, Form, Modal, Table, Tabs, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import { PlusCircleFilled } from "@ant-design/icons";
import TabPane from "antd/es/tabs/TabPane";
import AddEmpToRole from "../Pages/AddEmpToRole";
import AddEmployee from "../Employee/AddEmployee";
import Loader from "../Loader/Loader";
import { LeftCircleOutlined } from "@ant-design/icons";
import "./Style.css";

const Roles = () => {
	const [roles, setRoles] = useState([
		"PAYROLL_MANAGER",
		"TALENT_MANAGER",
		"NETWORK_IT_ADMIN",
		"ASSETS_MANAGER",
		"RELATIONSHIP_MANAGER",
		"ACCOUNTS_MANAGER",
	]);
	const navigate = useNavigate();
	const [selectedRoles, setSelectedRoles] = useState([]);
	const { apiurl, token } = useAuth();
	const [existingRoles, setExistingRoles] = useState([]);
	const [roleName, setRoleName] = useState("");
	const [childId, setChildId] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalVisible1, setIsModalVisible1] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetchRoles();
	}, []);
	const fetchRoles = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchroles/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setExistingRoles(data.roles);
				const existingRoleNames = data.roles.map((role) => role.name);
				const updatedRoles = roles.filter(
					(role) => !existingRoleNames.includes(role)
				);
				setRoles(updatedRoles);
			} else {
				message.error("Unable to process. Please try again");
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const handleRoleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (selectedRoles.length === 0) {
			message.error("Please select at least one role.");
			setLoading(false);
			return;
		}

		try {
			const response = await fetch(`${apiurl}/orgstructure/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					roles: selectedRoles,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				message.success(data.data);
				fetchRoles();

				setSelectedRoles([]);
			} else {
				message.error("Unable to process. Please try again");
			}
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
			setIsModalVisible1(false);
		}
	};
	const columns = [
		{
			title: "Role Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "User",
			dataIndex: "user",
			key: "user",
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) =>
				record.name !== "HR_HEAD" &&
				record.name !== "BUSINESS_OWNER" && (
					<Button
						onClick={() => {
							setRoleName(record.name);
							setChildId(record.child_id);
							setIsModalVisible(true);
						}}>
						Update User
					</Button>
				),
		},
	];

	if (loading) {
		return <Loader />;
	}
	return (
		<HeaderComponent>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<div className="roles-main">
				<h2>
					Roles{" "}
					<Button type="text" onClick={() => setIsModalVisible1(true)}>
						<PlusCircleFilled style={{ fontSize: "20px" }} />
					</Button>
				</h2>
				<Table
					pagination={false}
					dataSource={existingRoles}
					columns={columns}
					rowKey="id"
				/>
				<Modal
					destroyOnClose={true}
					title="Add Roles"
					open={isModalVisible1}
					onCancel={() => {
						setIsModalVisible1(false);
					}}
					footer={null}>
					<Form layout="vertical" className="roles-form">
						<Form.Item>
							{roles.map((role) => (
								<Checkbox
									key={role}
									checked={selectedRoles.includes(role)}
									onChange={(e) => {
										setSelectedRoles((prevRoles) => {
											if (e.target.checked) {
												return [...prevRoles, role];
											} else {
												return prevRoles.filter((r) => r !== role);
											}
										});
									}}>
									{role.replace(/_/g, " ")}
								</Checkbox>
							))}
						</Form.Item>
						<Button type="primary" onClick={handleRoleSubmit}>
							Submit Roles
						</Button>
					</Form>
				</Modal>
			</div>
			<Modal
				destroyOnClose={true}
				title="Add Role"
				open={isModalVisible}
				onCancel={() => {
					setIsModalVisible(false);
				}}
				footer={null}>
				<div className="choice-selection">
					<Tabs defaultActiveKey="1" style={{ margin: "10px" }}>
						<TabPane tab="Add Existing" key="1">
							<AddEmpToRole role={roleName} child_id={childId}></AddEmpToRole>
						</TabPane>
						<TabPane tab="Add New" key="2">
							<AddEmployee role={roleName} child_id={childId}></AddEmployee>
						</TabPane>
					</Tabs>
				</div>
			</Modal>
		</HeaderComponent>
	);
};

export default Roles;
