import React, { useState } from "react";
import { Button, Select, message, Table } from "antd";
import Papa from "papaparse";
import { useAuth } from "../utils/useAuth";

const { Option } = Select;

const PayrollExportComponent = () => {
	const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
	const [csvData, setCsvData] = useState("");
	const [parsedData, setParsedData] = useState([]);
	const { apiurl, token } = useAuth();

	const handleExportPayroll = async () => {
		if (!month || !year) {
			message.error("Please select both month and year");
			return;
		}

		try {
			const response = await fetch(`${apiurl}/export-payroll/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childId: sessionStorage.getItem("selectedChildId"),
					month: month,
					year: year,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch payroll data");
			}
			const text = await response.text();
			setCsvData(text);
			handleCsvParse(text);
		} catch (error) {
			message.error("Error fetching payroll data");
			console.error(error);
		}
	};

	const handleDownloadCsv = () => {
		const csvBlob = new Blob([csvData], { type: "text/csv" });
		const url = window.URL.createObjectURL(csvBlob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "payroll_data.csv";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const handleCsvParse = (csvText) => {
		Papa.parse(csvText, {
			header: true,
			complete: (results) => {
				setParsedData(results.data);
			},
			error: (error) => {
				message.error("Error parsing CSV");
				console.error(error);
			},
		});
	};

	// Generate columns based on parsed data
	const generateColumns = () => {
		if (parsedData.length === 0) return [];

		// Extract the keys from the first row of parsed data
		const keys = Object.keys(parsedData[0]);
		const dynamicColumns = keys.map((key) => ({
			title: key,
			dataIndex: key,
			key: key,
		}));

		return dynamicColumns;
	};

	const columns = generateColumns();

	return (
		<div className="export_pay-container">
			<Button
				className="export_pay-back-button"
				onClick={() => window.history.back()}>
				Back
			</Button>
			<div className="export_pay-controls">
				<Select
					className="export_pay-month-select"
					placeholder="Select Month"
					onChange={(value) => setMonth(value)}
					value={month}>
					<Option value="1">January</Option>
					<Option value="2">February</Option>
					<Option value="3">March</Option>
					<Option value="4">April</Option>
					<Option value="5">May</Option>
					<Option value="6">June</Option>
					<Option value="7">July</Option>
					<Option value="8">August</Option>
					<Option value="9">September</Option>
					<Option value="10">October</Option>
					<Option value="11">November</Option>
					<Option value="12">December</Option>
				</Select>
				<Select
					className="export_pay-year-select"
					placeholder="Select Year"
					onChange={(value) => setYear(value)}
					value={year}>
					<Option value="2023">2023</Option>
					<Option value="2024">2024</Option>
					<Option value="2025">2025</Option>
				</Select>
				<Button
					className="export_pay-export-button"
					type="primary"
					onClick={handleExportPayroll}>
					Export Payroll
				</Button>
			</div>

			{parsedData.length > 0 && (
				<div className="export_pay-data-display">
					<Button
						className="export_pay-download-button"
						style={{ marginBottom: "10px" }}
						onClick={handleDownloadCsv}>
						Download CSV
					</Button>
					<p>CSV Data:</p>
					<Table
						className="export_pay-table"
						columns={columns}
						dataSource={parsedData}
						rowKey={(record, index) => index}
						pagination={{ pageSize: 10 }}
						scroll={{ x: "max-content" }}
					/>
				</div>
			)}
		</div>
	);
};

export default PayrollExportComponent;
