import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Select, message, Space } from "antd";
import { useAuth } from "../utils/useAuth";
import HeaderComponent from "../Header/Header";

const EmployeeAssetFormView = () => {
	const { apiurl, token } = useAuth();
	const [employees, setEmployees] = useState([]);
	const [assetTypes, setAssetTypes] = useState([]);
	const [selectedAssetDetails, setSelectedAssetDetails] = useState(null);
	const [childId] = useState(sessionStorage.getItem("selectedChildId"));
	const [loading, setLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [currentEmployeeId, setCurrentEmployeeId] = useState(null);
	const [expandedRowKey, setExpandedRowKey] = useState(null); 

	useEffect(() => {
		fetchEmployeeAssets();
		fetchAssetTypes();
	}, [childId]);

	const fetchEmployeeAssets = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${apiurl}/employee-assets?child_id=${childId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			const data = await response.json();
			if (response.ok) {
				setEmployees(data);
			} else {
				message.error(
					data.error || "Failed to fetch employees and their assets."
				);
			}
		} catch (error) {
			message.error("An error occurred while fetching employee assets.");
		}
		setLoading(false);
	};

	const fetchAssetTypes = async () => {
		try {
			const response = await fetch(`${apiurl}/assets?child_id=${childId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			if (response.ok) {
				setAssetTypes(data);
			} else {
				message.error(data.error || "Failed to fetch asset types.");
			}
		} catch (error) {
			message.error("An error occurred while fetching asset types.");
		}
	};

	const handleAssetChange = async (assetId) => {
		if (!assetId) {
			setSelectedAssetDetails(null);
			return;
		}

		const assetDetails = await fetchAssetDetails(assetId);
		setSelectedAssetDetails(assetDetails);
	};

	const fetchAssetDetails = async (assetId) => {
		try {
			const response = await fetch(
				`${apiurl}/asset-details?asset_id=${assetId}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			const data = await response.json();
			return data || null;
		} catch (error) {
			message.error("Failed to fetch asset details.");
			return null;
		}
	};

	const handleSubmit = async (values) => {
		const url = `${apiurl}/employee-assets`;

		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});

			const result = await response.json();
			if (response.ok) {
				message.success("Asset issued successfully");
				fetchEmployeeAssets();
				setModalVisible(false);
				form.resetFields();
			} else {
				message.error(
					result.error || "An error occurred while processing your request."
				);
			}
		} catch (error) {
			message.error("Failed to submit asset issuance.");
		}
	};

	const handleDelete = async (id) => {
		try {
			const response = await fetch(`${apiurl}/employee-assets`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ id }),
			});
			if (response.ok) {
				message.success("Asset issuance record deleted successfully");
				fetchEmployeeAssets();
			} else {
				const result = await response.json();
				message.error(result.error || "Failed to delete asset issuance");
			}
		} catch (error) {
			message.error("Failed to delete asset issuance.");
		}
	};

	const openModal = (employeeId) => {
		setCurrentEmployeeId(employeeId);
		setModalVisible(true);
		form.resetFields();
		setSelectedAssetDetails(null);
	};

	
	const handleExpand = (expanded, record) => {
		console.log(expanded, record.index);
		setExpandedRowKey(expanded ? record.index : null); 
	};

	const columns = [
		{
			title: "Employee Name",
			dataIndex: "employee",
			render: (text) => text.userName,
		},
		{
			title: "Designation",
			dataIndex: "employee",
			render: (text) => text.designation,
		},
		{
			title: "Count of Issued Assets",
			dataIndex: "issued_assets",
			render: (issuedAssets) => issuedAssets?.length || 0,
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<Space>
					<Button type="primary" onClick={() => openModal(record.employee.id)}>
						Add Asset
					</Button>
				</Space>
			),
		},
	];

	const expandedRowRender = (record) => {
		const issuedAssets = record.issued_assets || [];

		const assetColumns = [
			{
				title: "Asset Name",
				dataIndex: "assetdetails",
				render: (text) => text.asset?.name,
			},
			{
				title: "Issued By",
				dataIndex: "issuedBy",
				render: (text) => text.userName,
			},
			{
				title: "Actions",
				key: "actions",
				render: (text, asset) => (
					<Space>
						<Button onClick={() => handleDelete(asset.id)} danger>
							Delete
						</Button>
					</Space>
				),
			},
		];

		return (
			<Table
				columns={assetColumns}
				dataSource={issuedAssets}
				rowKey="id"
				pagination={false}
			/>
		);
	};

	return (
		<HeaderComponent>
			<div className="employee-asset-form-view">
				<h1>Employee Asset Issuances</h1>
				{loading ? (
					<p>Loading...</p>
				) : (
					<Table
						dataSource={employees}
						columns={columns}
						rowKey="index"
						expandable={{
							expandedRowRender,
							expandedRowKeys: expandedRowKey ? [expandedRowKey] : [], 
							onExpand: handleExpand, 
						}}
					/>
				)}

				<Modal
					title="Issue Asset"
					open={modalVisible}
					onCancel={() => setModalVisible(false)}
					onOk={() => form.submit()}>
					<Form form={form} layout="vertical" onFinish={handleSubmit}>
						<Form.Item
							label="Employee"
							name="employee_id"
							initialValue={currentEmployeeId}
							rules={[
								{ required: true, message: "Please select an employee" },
							]}>
							<Select placeholder="Select an employee" disabled>
								{employees.map((employee) => (
									<Select.Option key={employee.id} value={employee.id}>
										{employee.userName}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label="Asset Details"
							name="asset_detail_id"
							rules={[
								{ required: true, message: "Please select an asset detail" },
							]}>
							<Select
								placeholder="Select an asset detail"
								onChange={handleAssetChange}>
								{assetTypes.map((asset) => (
									<Select.Option key={asset.id} value={asset.id}>
										{asset.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label="Asset Configuration"
							name="asset_configuration"
							rules={[
								{
									required: true,
									message: "Please select asset configuration",
								},
							]}>
							<Select
								placeholder="Select asset configuration"
								disabled={!selectedAssetDetails}>
								{selectedAssetDetails &&
									selectedAssetDetails.map((detail) => (
										<Select.Option key={detail.id} value={detail.id}>
											{detail.serial_number || "N/A"}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		</HeaderComponent>
	);
};

export default EmployeeAssetFormView;
