import React, { useEffect, useState } from "react";
import {
	Table,
	Button,
	Form,
	Input,
	Modal,
	Select,
	Alert,
	Popconfirm,
	message,
    Card,
} from "antd";
import { useAuth } from "../utils/useAuth";
import { PlusOutlined } from "@ant-design/icons";


const { Option } = Select;

const LeaveApprovalFlow = () => {
	const { apiurl, token } = useAuth();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [editingFlow, setEditingFlow] = useState(null);
	const [visible, setVisible] = useState(false);
	const [form] = Form.useForm();

	const fetchLeaveApprovalFlows = async () => {
		setLoading(true);
		const childid = await sessionStorage.getItem("selectedChildId");
		try {
			const response = await fetch(
				`${apiurl}/leaveapprovalflow?child_id=${childid}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			const result = await response.json();
			setData(result);
		} catch (error) {
			message.error("Error fetching leave approvals");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchLeaveApprovalFlows();
	}, []);

	const handleCreate = async (values) => {
		try {
			const childid = await sessionStorage.getItem("selectedChildId");
			const response = await fetch(`${apiurl}/leaveapprovalflow/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					child_id: childid,
					days: values.days,
					level: values.level,
					approvingPerson: values.approvingPerson,
				}),
			});
			const result = await response.json();
			if (response.ok) {
				message.success("Leave approval flow created successfully");
				fetchLeaveApprovalFlows();
			} else {
				message.error(result.error || "Error creating leave approval");
			}
		} catch (error) {
			message.error("Error creating leave approval");
		}
	};

	const handleUpdate = async (values) => {
		try {

			const response = await fetch(`${apiurl}/leaveapprovalflow/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					laf_id: editingFlow.id,
					days: values.days,
					level: values.level,
					approvingPerson: values.approvingPerson,
				}),
			});
			const result = await response.json();
			if (response.ok) {
				message.success("Leave approval flow updated successfully");
				fetchLeaveApprovalFlows();
				setVisible(false);
			} else {
				message.error(result.error || "Error updating leave approval");
			}
		} catch (error) {
			message.error("Error updating leave approval");
		}
	};

	const handleDelete = async (id) => {
		try {
			const response = await fetch(`${apiurl}/leaveapprovalflow/`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ laf_id: id }),
			});
			const result = await response.json();
			if (response.ok) {
				message.success("Leave approval flow deleted successfully");
				fetchLeaveApprovalFlows();
			} else {
				message.error(result.error || "Error deleting leave approval");
			}
		} catch (error) {
			message.error("Error deleting leave approval");
		}
	};

	const onFinish = (values) => {
		if (editingFlow) {
			handleUpdate(values);
		} else {
			handleCreate(values);
		}
	};

	const handleEdit = (record) => {
		setEditingFlow(record);
		form.setFieldsValue(record);
		setVisible(true);
	};

	return (
		<div>
			<Card
				className="leave-policy-card"
				bordered={false}
				title="Leave Approval Flows"
				extra={
					<Button icon={<PlusOutlined />} onClick={() => setVisible(true)} />
				}>
				<Table
					dataSource={data}
					columns={[
						{
							title: "Days",
							dataIndex: "days",
							key: "days",
						},
						{
							title: "Level",
							dataIndex: "level",
							key: "level",
						},
						{
							title: "Approving Person",
							dataIndex: "approvingPerson",
							key: "approvingPerson",
						},
						{
							title: "Actions",
							key: "actions",
							render: (text, record) => (
								<>
									<Button type="link" onClick={() => handleEdit(record)}>
										Edit
									</Button>
									<Popconfirm
										title="Are you sure you want to delete this leave approval flow?"
										onConfirm={() => handleDelete(record.id)}
										okText="Yes"
										cancelText="No">
										<Button type="link" danger>
											Delete
										</Button>
									</Popconfirm>
								</>
							),
						},
					]}
					rowKey="id"
					loading={loading}
				/>
			</Card>
			<Modal
				open={visible}
				title={
					editingFlow ? "Edit Leave Approval Flow" : "Add Leave Approval Flow"
				}
				onCancel={() => {
					setVisible(false);
					setEditingFlow(null);
					form.resetFields();
				}}
				onOk={() => form.submit()}>
				<Form form={form} onFinish={onFinish} layout="vertical">
					<Form.Item>
						<Alert
							message="Important Information"
							description="If the approving person is 'HR', the leave will be processed as is. If the approving person is 'Reporting Manager', the leave will be forwarded based on the level. For example, if the level is 2, it will go to the Reporting Manager's manager."
							type="info"
							showIcon
						/>
					</Form.Item>
					<Form.Item
						name="days"
						label="Days"
						rules={[{ required: true, message: "Please input days!" }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="level"
						label="Level"
						rules={[{ required: true, message: "Please input level!" }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="approvingPerson"
						label="Approving Person"
						rules={[
							{ required: true, message: "Please select approving person!" },
						]}>
						<Select placeholder="Select approving person">
							<Option value="HR">HR</Option>
							<Option value="Reporting Manager">Reporting Manager</Option>
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default LeaveApprovalFlow;
