import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Table, message, Modal, Input, Spin } from "antd";
import { useAuth } from "../utils/useAuth";
import HeaderComponent from "../Header/Header";
import { LeftCircleOutlined } from "@ant-design/icons";
import "./Payroll.css";
import Loader from "../Loader/Loader";
import Success from "../utils/Success";

const AdminPayroll = () => {
	const [userData, setUserData] = useState([]);
	const [payrollData, setPayrollData] = useState([]);
	const [showPayrollForm, setShowPayrollForm] = useState(false);
	const navigate = useNavigate();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [currentEdit, setCurrentEdit] = useState(null);
	const [loading, setLoading] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false); 
	const [success, setSuccess] = useState(false);
	const { apiurl, token } = useAuth();
	const { month, year } = useParams();


	const fetchUserData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/payrollinfo/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					month: month,
					year: year,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch payroll data");
			}
			const data = await response.json();
			setUserData(data.data);
		} catch (error) {
			message.error("Error fetching payroll data");
		} finally {
			setLoading(false); 
		}
	};

	const fetchPayrollData = async () => {
		setLoading(true); 
		try {
			const response = await fetch(`${apiurl}/payroll-preview/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					month: month,
					year: year,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch payroll data");
			}
			const data = await response.json();
			setPayrollData(data);
		} catch (error) {
			message.error("Error fetching payroll data");
		} finally {
			setLoading(false); 
		}
	};

	const runPayroll = async () => {
		setButtonLoading(true); 
		try {
			const response = await fetch(`${apiurl}/runpayroll/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					month: month,
					year: year,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch payroll data");
			}
			const data = await response.json();
			setUserData(data.data);
			setSuccess(true);
		} catch (error) {
			message.error("Error running payroll");
		} finally {
			setButtonLoading(false); 
		}
	};

	const notifymanager = async () => {
		setButtonLoading(true); 
		try {
			const response = await fetch(`${apiurl}/notify-manager/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					month: month,
					year: year,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to notify manager");
			}
			message.success("Notified to accounts manager");
		} catch (error) {
			message.error("Error notifying manager");
		} finally {
			setButtonLoading(false); 
		}
	};

	const handleSave = async () => {
		if (!currentEdit) return;
		setLoading(true); 
		try {
			const response = await fetch(`${apiurl}/payrollinfo/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(currentEdit),
			});
			if (!response.ok) {
				throw new Error("Failed to update payroll data");
			}
			await fetchPayrollData(); 
			setIsModalVisible(false);
			message.success("Payroll updated successfully!");
		} catch (error) {
			message.error("Error updating payroll data");
		} finally {
			setLoading(false); 
		}
	};

	const handleInputChange = (e, field) => {
		setCurrentEdit({
			...currentEdit,
			[field]: e.target.value,
		});
	};

	const showModal = (record) => {
		setCurrentEdit(record);
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setCurrentEdit(null);
	};

	useEffect(() => {
		fetchPayrollData();
		fetchUserData();
	}, []);

	const columns = [
		{ title: "Employee", dataIndex: "employee", key: "employee" },
		{ title: "Parent", dataIndex: "parent", key: "parent" },
		{ title: "Child", dataIndex: "child", key: "child" },
		{ title: "CTC", dataIndex: "ctc", key: "ctc" },
		{ title: "Leaves Taken", dataIndex: "leaves", key: "leaves" },
		{ title: "Absent", dataIndex: "absent", key: "absent" },
		{
			title: "Current Leave Balance",
			dataIndex: "currentleavebalance",
			key: "currentleavebalance",
		},
		{
			title: "Carry Forwarded Leave Balance",
			dataIndex: "forwardedleavebalance",
			key: "forwardedleavebalance",
		},
		{ title: "Bonus", dataIndex: "bonus", key: "bonus" },
		{ title: "Advance Amount", dataIndex: "advance", key: "advance" },
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			render: (_, record) => (
				<Button onClick={() => showModal(record)}>Edit</Button>
			),
		},
		{
			title: "Edit Attendance",
			dataIndex: "edit",
			key: "edit",
			render: (_, record) => (
				<Button
					onClick={() =>
						navigate(`/empattendance/${month}/${year}/${record.id}`)
					}>
					Edit
				</Button>
			),
		},
	];

	const payrollcolumns = [
		{ title: "Parent", dataIndex: "parent", key: "parent" },
		{ title: "Child", dataIndex: "child", key: "child" },
		{
			title: "Employee Name",
			dataIndex: ["employee", "userName"],
			key: "userName",
		},
		{
			title: "Employee ID",
			dataIndex: ["employee", "employeeid"],
			key: "employeeid",
		},
		{
			title: "Designation",
			dataIndex: ["employee", "designation"],
			key: "designation",
		},
		{
			title: "Department",
			dataIndex: ["employee", "department"],
			key: "department",
		},
		{ title: "Month", dataIndex: "month", key: "month" },
		{ title: "Year", dataIndex: "year", key: "year" },
		{ title: "Gross Salary", dataIndex: "gross", key: "gross" },
		{ title: "LOP", dataIndex: "lop", key: "lop" },
		{ title: "Number of Leaves", dataIndex: "no_leaves", key: "no_leaves" },
		{ title: "Basic Salary", dataIndex: "basic_salary", key: "basic_salary" },
		{
			title: "Allowances",
			dataIndex: "allowances",
			key: "allowances",
			render: (allowances) => {
				const parsedAllowances = JSON.parse(allowances);
				return (
					<div>
						<p>HRA: {parsedAllowances.HRA}</p>
						<p>Travel: {parsedAllowances.Travel}</p>
						<p>Special: {parsedAllowances.Special}</p>
					</div>
				);
			},
		},
		{ title: "Net Salary", dataIndex: "net_salary", key: "net_salary" },
		{ title: "PF Deduction", dataIndex: "pf_deduction", key: "pf_deduction" },
		{
			title: "Tax Deduction",
			dataIndex: "tax_deduction",
			key: "tax_deduction",
		},
		{ title: "Employer PF", dataIndex: "employer_pf", key: "employer_pf" },
		{ title: "Employer ESI", dataIndex: "employer_esi", key: "employer_esi" },
		{
			title: "ESI Deduction",
			dataIndex: "esi_deduction",
			key: "esi_deduction",
		},
		{ title: "Status", dataIndex: "status", key: "status" },
	];

	
	if (loading) {
		return <Loader />;
	}

	
	if (success) {
		return (
			<Success
				title={
					<p>
						Payroll Processed Successfully <br />
						Click <Link to="/home">Here</Link> to return Home
					</p>
				}
			/>
		);
	}

	return (
		<HeaderComponent>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			{!showPayrollForm && (
				<div className="payroll-table">
					<Table dataSource={payrollData} columns={payrollcolumns} />
					<Button
						className="pay-btn"
						loading={buttonLoading}
						onClick={() => setShowPayrollForm(true)}>
						{payrollData.length > 0 ? "Re-run Payroll" : "Run Payroll"}
					</Button>
					{payrollData.length > 0 && (
						<Button
							className="pay-btn"
							loading={buttonLoading}
							onClick={notifymanager}>
							Notify Accounts Manager
						</Button>
					)}
				</div>
			)}
			{showPayrollForm && (
				<div className="admin-payroll-main">
					<Table dataSource={userData} columns={columns} />
					<Button
						className="pay-btn"
						loading={buttonLoading}
						onClick={runPayroll}>
						Run Payroll
					</Button>
					<Modal
						title="Edit Payroll"
						open={isModalVisible}
						onOk={handleSave}
						onCancel={handleCancel}
						okText="Save"
						cancelText="Cancel">
						{loading ? (
							<Spin />
						) : (
							<>
								<Input
									addonBefore="Bonus"
									value={currentEdit?.bonus}
									onChange={(e) => handleInputChange(e, "bonus")}
								/>
								<Input
									addonBefore="Advance"
									value={currentEdit?.advance}
									onChange={(e) => handleInputChange(e, "advance")}
								/>
								<Input
									addonBefore="Current Leave Balance"
									value={currentEdit?.currentleavebalance}
									onChange={(e) => handleInputChange(e, "currentleavebalance")}
								/>
								<Input
									addonBefore="Forwarded Leave Balance"
									value={currentEdit?.forwardedleavebalance}
									onChange={(e) =>
										handleInputChange(e, "forwardedleavebalance")
									}
								/>
								<Input
									addonBefore="CTC"
									value={currentEdit?.ctc}
									onChange={(e) => handleInputChange(e, "ctc")}
								/>
								<Input
									addonBefore="Comments"
									required
									onChange={(e) => handleInputChange(e, "comments")}
								/>
							</>
						)}
					</Modal>
				</div>
			)}
		</HeaderComponent>
	);
};

export default AdminPayroll;
