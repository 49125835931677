import React from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";

const Home = () => {
	const navigate = useNavigate();
	return (
		<div className="business-owner-home">
			<h2>HR Functionalities</h2>
			<div className="functionalities">
				<div className="action-item" onClick={() => navigate("/hr/policies")}>
					<div className="action-item-header">Policies</div>
					<div className="action-item-body">
						Click here to view and manage the policies of your organization.
					</div>
				</div>

				<div
					className="action-item"
					onClick={() => navigate("/hr/departments-designations")}>
					<div className="action-item-header">
						Departments and Designations
					</div>
					<div className="action-item-body">
						Click here to view and manage departments and designations of your
						organization.
					</div>
				</div>
				<div className="action-item" onClick={() => navigate("/hr/roles")}>
					<div className="action-item-header">Roles</div>
					<div className="action-item-body">
						Click here to view and manage the roles in your organization.
					</div>
				</div>
				<div className="action-item" onClick={() => navigate("/hr/appraisals")}>
					<div className="action-item-header">Appraisals</div>
					<div className="action-item-body">
						Click here to send appraisals to your employees
					</div>
				</div>
				<div className="action-item" onClick={() => navigate("/hr/grievances")}>
					<div className="action-item-header">Grievances</div>
					<div className="action-item-body">
						Click here to view and manage the grievances of employees in your
						organization.
					</div>
				</div>
				<div
					className="action-item"
					onClick={() => navigate("/hr/attendancerequest")}>
					<div className="action-item-header">Attendance Requests</div>
					<div className="action-item-body">
						Click here to view and manage the attendance requests of employees
						who missed punch their attendance in your organization.
					</div>
				</div>
				<div
					className="action-item"
					onClick={() => navigate("/hr/compoffrequest")}>
					<div className="action-item-header">
						Compensation Off
					</div>
					<div className="action-item-body">
						Click here to view and manage the compensation off requests of
						employees in your organization.
					</div>
				</div>
				<div className="action-item" onClick={() => navigate("/hr/ips")}>
					<div className="action-item-header">IP's</div>
					<div className="action-item-body">
						Click here to view and manage the IP addresses and the IP exceptions
						of employees of your organization .
					</div>
				</div>
				<div className="action-item" onClick={() => navigate("/empinorg")}>
					<div className="action-item-header">View Employees</div>
					<div className="action-item-body">
						Click here to view all the employees and their leave balances along
						with CTC of all the employees in your organization with respect to
						month.
					</div>
				</div>
				<div className="action-item" onClick={() => navigate("/hr/holidays")}>
					<div className="action-item-header">Holidays</div>
					<div className="action-item-body">
						Click here to add holidays available for your organization.
					</div>
				</div>
				<div className="action-item" onClick={() => navigate("/hr/workingdays")}>
					<div className="action-item-header">Working Days</div>
					<div className="action-item-body">
						Click here to view and manage working days of your organization.
					</div>
				</div>
				<div className="action-item" onClick={() => navigate("/exportpayroll")}>
					<div className="action-item-header">Export Payroll Data</div>
					<div className="action-item-body">
						Click here to export the payroll information of a particular month
						of all the employees in your organization along with PF and ESI.
					</div>
				</div>
				<div
					className="action-item"
					onClick={() => navigate("/optholidaysdata")}>
					<div className="action-item-header">Optional Holidays</div>
					<div className="action-item-body">
						Click here to manage the optional holidays in the organization.
					</div>
				</div>
				<div className="action-item" onClick={() => navigate("/hr/birthdays")}>
					<div className="action-item-header">Employee Birthdays</div>
					<div className="action-item-body">
						Click here to view the employees birthday
					</div>
				</div>
				<div className="action-item" onClick={() => navigate("/hr/resignations")}>
					<div className="action-item-header">Employee Resignations</div>
					<div className="action-item-body">
						Click here to view and manage your employees resignations
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
