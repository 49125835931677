import { Route, Routes } from "react-router-dom";
import ParentAccountForm from "./components/ParentAccount/ParentAccount";
import Login from "./components/userauth/login";
import ParentAccoutReg from "./components/RegistrationPage/RegistrationPage";
import { AuthRoute } from "./components/utils/AuthRoute";
import MAIN_HOME from "./components/Homepages/Main/Main";
import RazorpayPaymentComponent from "./components/utils/PaymentHandler";
import ChildAccountDetails from "./components/ChildAccount/ChildAccountDetails";
import Childs from "./components/ChildAccount/Childs";
import ResetPasswordForm from "./components/Forgotpassword/Reset";
import ForgotPassword from "./components/Forgotpassword/Forgot";
import ChangePasswordForm from "./components/Forgotpassword/Change";
import BulkUpload from "./components/Employee/Bulkupload";
import Profile from "./components/Homepages/Bussiness_Owner/Profile";
import AttendanceMain from "./components/Attendance/AttendanceMain";
import Leaves from "./components/Leave/Leave";
import Notifications from "./components/Pages/Notifications";
import Attendance from "./components/Attendance/Attendance";
import Approvals from "./components/Approvals/Approvals";
import ChildDetails from "./components/Homepages/Functionalities/Childs";
import GrievanceDetails from "./components/Grievance/GrievanceDetails";
import CurrentChildDetails from "./components/Child/CurrentChildDetails";
import GrievanceHrList from "./components/Grievance/GrievanceHrList";
import GrievanceHrDetails from "./components/Grievance/GrievanceHrDetails";
import Policy from "./components/Policy/Policy";
import Roles from "./components/Roles/Roles";
import DepDes from "./components/Homepages/HR_HEAD/DepDes";
import Employees from "./components/Employee/Employees";
import AddEmployeeComponent from "./components/Homepages/TALENT_MANAGER/AddEmp";
import TicketDetails from "./components/Ticket/TicketDetails";
import TicketHrDetails from "./components/Ticket/TicketHrDetails";
import TicketHRList from "./components/Ticket/TicketHRList";
import AdminPayroll from "./components/Payroll/AdminPayroll";
import UserPayroll from "./components/Payroll/UserPayroll";
import AdminApproval from "./components/AdminApprovals/AdminApproval";
import UploadLeaveBalance from "./components/Leave/UploadLeaveBalance";
import AttendanceRequest from "./components/Attendance/AttendanceRequest";
import HRAttendanceRequest from "./components/Attendance/HRAttendance";
import Ipadd from "./components/IPAddr/Ipadd";
import ExitInterviewForm from "./components/ExitFormalities";
import GrievanceMain from "./components/Grievance/Main";
import GreetingsList from "./components/Greetings/Greetings";
import CalendarComponent from "./components/Clock/Calendar";
import Holidays from "./components/Homepages/HR_HEAD/Holidays";
import AttendancePolicy from "./components/Policy/AttendancePolicy";
import AttendanceRequestPolicy from "./components/Policy/AttendanceRequestPolicy";
import HRCompOffRequest from "./components/Attendance/HRCompRequests";
import IPException from "./components/IPAddr/Ipexceptions";
import Sucess from "./components/Forgotpassword/success";
import PayrollExportComponent from "./components/Payroll/Payroll_export";
import Logout from "./components/userauth/logout";
import AddQuote from "./components/AddQuote";
import CompOffForm from "./components/Attendance/CompOffForm";
import AttReq from "./components/Attendance/AttReq";
import ReqLateLogin from "./components/Attendance/ReqLateLogin";
import SendNotification from "./components/utils/SendNotification";
import EmployeeData from "./components/Homepages/Main/EmployeeData";
import EmpInOrg from "./components/Homepages/HR_HEAD/EmpInOrg";
import EmployeesData from "./components/Homepages/HR_HEAD/EmployeesData";
import IpRestriction from "./components/IpRestriction";

import HomePage from "./components/Pages/Main";
import TicketList from "./components/Ticket/TicketList";
import UploadOptHolidays from "./components/Homepages/HR_HEAD/UploadOptHolidays";
import OptionalHoliday from "./components/Leave/OptionalHoliday";
import OptionalHolidaysData from "./components/Leave/OptionalHolidaysData";
import Appraisal from "./components/Homepages/HR_HEAD/Appraisal";
import EmployeeInfo from "./components/Homepages/HR_HEAD/EmployeeInfo";
import EmployeeBirthdays from "./components/Homepages/HR_HEAD/EmployeeBirthdays";
import AttendanceRecords from "./components/Attendance/AttendanceRecords";
import EmpDataforrm from "./components/Homepages/HR_HEAD/EmpDataforrm";
import EditEmpAtt from "./components/Payroll/EditEmpAtt";
import Form12BBComponent from "./components/InvestmentProofing/Form12BB";
import OnboardEmployee from "./components/Employee/OnboardEmployee";
import ChatApp from "./components/Chat/ChatApp";
import MonthlyDataList from "./components/Homepages/HR_HEAD/MonthlyData";
import ManageAssets from "./components/Assets/ManageAssets";
import AssetDetailsView from "./components/Assets/AssetDetails";
import EmployeeAssetFormView from "./components/Assets/EmployeeAssets";
import UpdateResignationStatusView from "./components/Resignation/ResignationHr";
import BroadcastMessageView from "./components/Chat/Broadcast";
import ValidatePayslip from "./components/Pages/ValidateQR";
import AccountsPayroll from "./components/Payroll/AccountsPayroll";
function App() {
	return (
		<>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/notify" element={<SendNotification />} />

				<Route path="/registration" element={<ParentAccoutReg />} />
				<Route
					path="/reset/:uidb64/:token"
					element={<ResetPasswordForm />}></Route>
				<Route path="/forgot" element={<ForgotPassword />}></Route>
				<Route path="/change/success" element={<Sucess />} />
				<Route path="/empinorg" element={<EmpInOrg />}></Route>
				<Route path="/empdataforrm" element={<EmpDataforrm />}></Route>
				<Route path="/removeip" element={<IpRestriction />}></Route>
				<Route
					path="/viewemployees/rm/:month/:year"
					element={<EmployeeData />}></Route>

				<Route path="/logout" element={<Logout />} />
				<Route path="/switchchild" element={<ChildDetails />} />
				<Route path="/addquote" element={<AddQuote />} />
				<Route path="/" element={<HomePage />} />
				<Route path="/validatepayslip/:code" element={<ValidatePayslip/>} />
				<Route path="/" element={<AuthRoute />}>
					<Route path="/hr/appraisals" element={<Appraisal />} />
					<Route path="/home" element={<MAIN_HOME />} />
					<Route path="/addorg" element={<ParentAccountForm />} />
					<Route
						path="/payment-gateway"
						element={<RazorpayPaymentComponent />}
					/>
					<Route path="/child-details" element={<Childs />} />
					<Route path="/child-details/:id" element={<ChildAccountDetails />} />
					<Route
						path="/viewemployees/:month/:year"
						element={<EmployeesData />}
					/>

					<Route path="/calendar" element={<CalendarComponent />} />
					<Route path="/employeeassets" element={<EmployeeAssetFormView />} />
					<Route path="/assets" element={<ManageAssets />} />
					<Route path="/assets/:asset_id" element={<AssetDetailsView />} />
					<Route path="/ipexceptions" element={<IPException />} />
					<Route path="/requests/approvals" element={<Approvals />} />
					<Route path="/adminapproval" element={<AdminApproval />} />
					<Route path="/change" element={<ChangePasswordForm />} />
					<Route path="/bulkupload" element={<BulkUpload />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/attendance" element={<Attendance />} />
					<Route path="/attendance/comp-off" element={<CompOffForm />} />
					<Route path="/attendance/request" element={<AttReq />} />
					<Route path="/attendance/latelogin" element={<ReqLateLogin />} />
					<Route path="/uploadatt" element={<AttendanceMain />} />
					<Route path="/uploadleavebal" element={<UploadLeaveBalance />} />
					<Route path="/leave-management" element={<Leaves />} />
					<Route path="/fetchchildaccounts" element={<ChildDetails />} />
					<Route path="/notifications" element={<Notifications />} />
					<Route path="/leaves" element={<Leaves />} />

					<Route path="/notifications" element={<Notifications />} />
					<Route path="/requests/grievance" element={<GrievanceMain />} />
					<Route path="/requests/ticket" element={<TicketList />} />
					<Route path="/grievances/:id" element={<GrievanceDetails />} />
					<Route path="/tickets/:id" element={<TicketDetails />} />
					<Route path="/child" element={<CurrentChildDetails />} />
					<Route path="/hr/grievances" element={<GrievanceHrList />} />
					<Route path="/hr/grievances/:id" element={<GrievanceHrDetails />} />
					<Route path="/hr/tickets" element={<TicketHRList />} />
					<Route path="/employees/:id" element={<EmployeeInfo />} />
					<Route path="/hr/birthdays" element={<EmployeeBirthdays />} />
					<Route path="/hr/tickets/:id" element={<TicketHrDetails />} />
					<Route path="/payroll/:month/:year" element={<AdminPayroll />} />
					<Route path="/accounts/payroll/:month/:year" element={<AccountsPayroll />} />
					<Route path="/exportpayroll" element={<PayrollExportComponent />} />
					<Route path="/hr/policies" element={<Policy />} />
					<Route path="/hr/roles" element={<Roles />} />
					<Route path="/hr/holidays" element={<Holidays />} />
					<Route path="/uploadoptholidays" element={<UploadOptHolidays />} />
					<Route path="/optholidaysdata" element={<OptionalHolidaysData />} />
					<Route path="/attendance/optholidays" element={<OptionalHoliday />} />
					<Route path="/attendance/records" element={<AttendanceRecords />} />
					<Route path="/hr/ips" element={<Ipadd />} />
					<Route path="/hr/departments-designations" element={<DepDes />} />
					<Route path="/employees" element={<Employees />} />
					<Route path="/add/employees" element={<AddEmployeeComponent />} />
					<Route
						path="/onboardemployee/:role/:child_id"
						element={<OnboardEmployee />}
					/>
					<Route path="/payslips" element={<UserPayroll />} />
					<Route
						path="/empattendance/:month/:year/:id"
						element={<EditEmpAtt />}
					/>
					<Route path="/requests/attrequest" element={<AttendanceRequest />} />
					<Route path="/attendancepolicy" element={<AttendancePolicy />} />
					<Route
						path="/attendancerequestpolicy"
						element={<AttendanceRequestPolicy />}
					/>
					<Route
						path="/hr/attendancerequest"
						element={<HRAttendanceRequest />}
					/>
					<Route path="/hr/compoffrequest" element={<HRCompOffRequest />} />
					<Route path="/exitformality" element={<ExitInterviewForm />} />
					<Route path="/greetings" element={<GreetingsList />} />
					<Route path="/investments/form12bb" element={<Form12BBComponent />} />
					<Route path="/chat" element={<ChatApp />} />
					<Route path="/hr/workingdays" element={<MonthlyDataList />} />
					<Route
						path="/hr/resignations"
						element={<UpdateResignationStatusView />}
					/>
					<Route path="/broadcast" element={<BroadcastMessageView />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
