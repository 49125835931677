import React, { useEffect, useState } from "react";
import Main from "../Layouts/Main";
import { useAuth } from "../../utils/useAuth";
import { Button, Form, Modal, message, Tabs } from "antd";
import BasicDetailsModal from "../../Employee/BasicDetails";
import { InboxOutlined, EditOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import "./Profile.css";
import Documents from "../../Employee/Documents";
import EducationDetails from "../../Employee/EducationDetails";
import Relationships from "../../Employee/Relationships";
import ExperienceDetails from "../../Employee/WorkExperience";
import Occasions from "../../Employee/Occasions";
import ResignationView from "../../Resignation/Resignation";
import BankDetails from "../../Employee/BankDetails";

const Profile = () => {
	const [empData, setEmpData] = useState(null);
	const { apiurl, token } = useAuth();
	const [profileImg, setProfileImg] = useState("");
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(null);
	const [imageEditMode, setImageEditMode] = useState(false);

	const [form] = Form.useForm();

	useEffect(() => {
		fetchEmployeeDetails();
		fetchImages();
	}, []);

	const fetchImages = async () => {
		try {
			const response = await fetch(`${apiurl}/handleimages/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setProfileImg(data.data.profile_image);
			} else {
				const data = await response.json();
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error fetching images");
		}
	};

	const updateImages = async (type) => {
		setLoading(true);
		if (file) {
			const formData = new FormData();
			formData.append("type", type);
			formData.append("image", file);

			try {
				const response = await fetch(`${apiurl}/handleimages/`, {
					method: "PUT",
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: formData,
				});
				if (response.ok) {
					const data = await response.json();
					message.success(data.message);
					setImageEditMode(false);
					fetchImages();
					setLoading(false);
				} else {
					const data = await response.json();
					message.error(data.error);
					setLoading(false);
				}
			} catch (error) {
				message.error("Error fetching images");
				setLoading(false);
			}
		} else {
			setLoading(false);
			message.warning("Please select a file to upload.");
		}
	};

	const fetchEmployeeDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/createemployee/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			const data = await response.json();

			if (response.ok) {
				setEmpData(data.data);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("An error occurred while processing your request.");
		}
	};

	const handleFileChange = (info) => {
		const { status, originFileObj } = info.file;
		if (status === "done") {
			setFile(originFileObj);
		} else if (status === "error") {
			message.error(info?.file?.error?.message);
		}
	};

	const customRequest = ({ file, onSuccess }) => {
		setFile(file);
		onSuccess("ok");
	};

	return (
		<Main>
			<div className="profile-container">
				<div className="head-sec">
					<div className="cover-photo"></div>

					<div className="profile-header">
						<div className="profile-avatar">
							<img src={`${apiurl}${profileImg}`} alt="profile" />
							<Button
								shape="circle"
								icon={<EditOutlined />}
								onClick={() => setImageEditMode(true)}
								className="edit-avatar-btn"
							/>
						</div>
						<div className="profile-info">
							<h1>{empData?.userName}</h1>
							<div>
								<p className="profile-role">
									📍{empData?.type?.replace(/_/g, " ")}
								</p>

								<p className="profile-location">🎯{empData?.designation}</p>
								<p className="profile-join-date">📅{empData?.dateOfBirth}</p>
							</div>
						</div>
					</div>
				</div>
				<Tabs type="card" defaultActiveKey="1" className="profile-tabs">
					<Tabs.TabPane tab="Basic Details" key="1">
						<BasicDetailsModal />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Bank Details" key="2">
						<BankDetails />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Documents" key="3">
						<Documents />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Education" key="4">
						<EducationDetails />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Relationships" key="5">
						<Relationships />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Occasions" key="6">
						<Occasions />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Experience" key="7">
						<ExperienceDetails />
					</Tabs.TabPane>
					<Tabs.TabPane tab="Resignation" key="8">
						<ResignationView />
					</Tabs.TabPane>
				</Tabs>
			</div>

			<Modal
				open={imageEditMode}
				className="profile-modal"
				destroyOnClose
				title="Edit Image"
				onCancel={() => {
					setImageEditMode(false);
					fetchImages();
				}}
				footer={[
					<Button key="cancel" onClick={() => setImageEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					onFinish={() => updateImages("profile")}>
					<Form.Item>
						<Dragger
							className="image-upload"
							accept=".png,.jpg,.jpeg"
							maxCount={1}
							customRequest={customRequest}
							onChange={handleFileChange}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag image to this area to upload
							</p>
							<p className="ant-upload-hint">Upload only image files</p>
						</Dragger>
					</Form.Item>
				</Form>
			</Modal>
		</Main>
	);
};

export default Profile;
