import React from "react";


const truncateText = (text, maxLength) => {
	return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

const RecentParticipants = ({
	conversations,
	onSelectConversation,
	selectedConversation,
}) => {
	return (
		<div className="recent-container">
			{conversations.map((conversation) => (
				<div
					key={conversation.id}
					onClick={() => onSelectConversation(conversation)}
					className={`recent-participant-item ${
						selectedConversation && selectedConversation.id === conversation.id
							? "active"
							: ""
					}`}>
					<div className="chat-message-avatar">
						{conversation?.participants[0]?.userName.charAt(0)}
					</div>
					<div>
						<div
							className={`recent-participant-name ${
								!conversation.is_seen ? "seen" : ""
							}`}>
							{conversation?.participants[0]?.userName}
						</div>
						<div className="recent-participant-message">
							{conversation?.last_message &&
								truncateText(conversation.last_message, 16)}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default RecentParticipants;
