import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Modal, message, Spin, Upload } from "antd";
import { EditOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Policy.css";
import Dragger from "antd/es/upload/Dragger";

const PayrollPolicy = () => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [fetchingPolicy, setFetchingPolicy] = useState(true);
	const [initialValues, setInitialValues] = useState({});
	const [isPolicy, setIsPolicy] = useState(false);
	const [logoFile, setLogoFile] = useState(null); 

	useEffect(() => {
		fetchPolicy();
	}, []);

	const fetchPolicy = async () => {
		setFetchingPolicy(true);
		try {
			const response = await fetch(
				`${apiurl}/payrollpolicy?childid=${sessionStorage.getItem(
					"selectedChildId"
				)}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				setInitialValues(data.data);
				setIsPolicy(true);
			} else {
				setIsPolicy(false);
			}
		} catch (error) {
			message.error("Error fetching policy.");
			setIsPolicy(false);
		} finally {
			setFetchingPolicy(false);
		}
	};

	const handleCreate = async (values) => {
		try {
			setLoading(true);
			const formData = new FormData();
			formData.append("payslipHeaderCompany", values.payslipHeaderCompany);
			formData.append(
				"payslipHeadertagline",
				values.payslipHeadertagline || ""
			);
			formData.append("payslipHeaderAddress", values.payslipHeaderAddress);
			formData.append("childid", sessionStorage.getItem("selectedChildId"));
			if (logoFile) {
				formData.append("logo", logoFile); 
			}

			const response = await fetch(`${apiurl}/payrollpolicy/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			if (response.ok) {
				message.success("Policy framed successfully");
				setCreateMode(false);
				fetchPolicy();
			} else {
				message.error("Failed to frame policy");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error framing policy");
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
		try {
			setLoading(true);
			const formData = new FormData();
			formData.append("payslipHeaderCompany", values.payslipHeaderCompany);
			formData.append(
				"payslipHeadertagline",
				values.payslipHeadertagline || ""
			);
			formData.append("payslipHeaderAddress", values.payslipHeaderAddress);
			formData.append("obj_id", initialValues.id);
			if (logoFile) {
				formData.append("logo", logoFile);
			}

			const response = await fetch(`${apiurl}/payrollpolicy/`, {
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			if (response.ok) {
				message.success("Details updated successfully");
				fetchPolicy();
				setEditMode(false);
			} else {
				message.error("Failed to update details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error updating details");
			setLoading(false);
		}
	};

	const handleLogoChange = (info) => {
		console.log(info.file);
		const { status, originFileObj } = info.file;
		if (status === "done") {
			setLogoFile(originFileObj);
		} else if (status === "error") {
			message.error(info?.file?.error?.message);
		}
	};

	const customRequest = ({ file, onSuccess }) => {
		setLogoFile(file);
		onSuccess("ok");
	};

	return (
		<div className="payrollpolicy-main">
			{fetchingPolicy ? (
				<div className="payrollpolicy-loader">
					<Spin size="large" />
				</div>
			) : isPolicy ? (
				<>
					<Card
						className="payrollpolicy-card"
						bordered={false}
						title="Payslip Layout"
						extra={
							<Button
								icon={<EditOutlined />}
								onClick={() => setEditMode(true)}
								disabled={editMode}
							/>
						}>
						<p>
							<span>Company Name:</span> {initialValues.payslipHeaderCompany}{" "}
						</p>
						<p>
							<span>Tag Line:</span> {initialValues.payslipHeadertagline}{" "}
						</p>
						<p>
							<span>Company Address:</span> {initialValues.payslipHeaderAddress}{" "}
						</p>
						<p>
							<span>Logo:</span>{" "}
							<br></br>
							{initialValues.payslipHeaderlogo && (
								<img
									src={`${apiurl}${initialValues.payslipHeaderlogo}`}
									alt="Company Logo"
									style={{ width: "150px" }}
								/>
							)}
						</p>
					</Card>
				</>
			) : (
				<>
					<h3 className="payrollpolicy-empty">
						Payroll Policy Not Yet Created.
					</h3>
					<Button onClick={() => setCreateMode(true)}>Create Policy</Button>
				</>
			)}

			<Modal
				open={createMode}
				title="Create Payroll Policy"
				destroyOnClose
				onCancel={() => setCreateMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setCreateMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form form={form} layout="vertical" onFinish={handleCreate}>
					<Form.Item required name="payslipHeaderCompany" label="Company Name">
						<Input />
					</Form.Item>
					<Form.Item name="payslipHeadertagline" label="Tag Line">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="payslipHeaderAddress"
						label="Company Address">
						<Input />
					</Form.Item>
					<Form.Item label="Upload Logo">
						<Dragger
							className="image-upload"
							accept=".png,.jpg,.jpeg"
							maxCount={1}
							customRequest={customRequest}
							onChange={handleLogoChange}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag image to this area to upload
							</p>
							<p className="ant-upload-hint">Upload only image files</p>
						</Dragger>
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				open={editMode}
				destroyOnClose
				title="Edit Details"
				onCancel={() => setEditMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleUpdate}
					initialValues={initialValues}>
					<Form.Item required name="payslipHeaderCompany" label="Company Name">
						<Input />
					</Form.Item>
					<Form.Item name="payslipHeadertagline" label="Tag Line">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="payslipHeaderAddress"
						label="Company Address">
						<Input />
					</Form.Item>
					<Form.Item label="Upload Logo">
						<Dragger
							className="image-upload"
							accept=".png,.jpg,.jpeg"
							maxCount={1}
							customRequest={customRequest}
							onChange={handleLogoChange}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag image to this area to upload
							</p>
							<p className="ant-upload-hint">Upload only image files</p>
						</Dragger>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default PayrollPolicy;
